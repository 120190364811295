const permissions = {
  ADMIN: 'admin', // 管理员数据
  COMPANY: 'company', // 公司数据
  PART: 'part', // 部门数据
  PEOPLE: 'people', // 个人数据
  DASHBOARD: 'dashboard', // Dashboard统计数据
  PP_AMOUNT: 'pp_amount', // PayPal收款明细
  ALL_AD_SPEND: 'all_ad_spend', // dashboard中总库账号/总库花费
  ROI_ANALYSIS: 'roi_analysis', // ROI分析
  PIXEL_ANALYSIS: 'pixel_analysis', // 像素分析
  GET_ORDERS_LIST: 'get_orders_list', // 订单列表
  ACCOUNT_SPEND: 'account_spend', // 账户花费
  CAMPAIGN_SPEND: 'campaign_spend', // 系列花费
  GET_HISTORY_SKU: 'get_history_sku', // 获取历史sku列表
  DEAL_HISTORY_SKU: 'deal_history_sku', // 创建/更新历史sku
  GET_SKU_REVIEW: 'get_sku_review', // 获取产品审核列表
  UPDATE_SKU_REVIEW: 'update_sku_review', // 更新产品审核状态
  DEAL_SKU_REVIEW: 'deal_sku_review', // 创建/删除产品审核
  GET_AD_ACCOUNT: 'get_ad_account', // 获取广告账号
  DEAL_AD_ACCOUNT: 'deal_ad_account', // 创建/更新广告账号
  SENSITIVE_AD_ACCOUNT: 'sensitive_ad_account', // 创建/更新广告账号敏感信息
  DEL_AD_ACCOUNT: 'del_ad_account', // 删除广告账号
  GET_USERS_LIST: 'get_users_list', // 获取用户列表
  DEAL_USER: 'deal_user', // 创建/更新子用户
  GET_PARTS: 'get_parts', // 获取部门列表
  DEAL_PARTS: 'deal_part', // 创建/更新部门信息
  GET_STORES: 'get_stores', // 获取店铺列表
  DEAL_STORE: 'deal_store', // 创建/更新店铺列表
  DEAL_STORES: 'deal_store', // 创建/更新店铺列表
  DEAL_PP_OPTION: 'deal_pp_option', // 新增/更新PP信息
  DEAL_PARTNER_OPTION: 'deal_partner_option', // 新增/更新合作者账号信息
  SEARCH_PP_OPTION: 'search_pp_option', // 搜索/展示PP信息
  SEARCH_PARTNER_OPTION: 'search_partner_option', // 搜索/展示合作者账号信息
  GET_PART_CONFIGS: 'get_part_configs', // 获取部门配置列表
  DEAL_PART_CONFIGS: 'deal_part_configs', // 创建/更新部门配置
  GET_GROUP_ROI: 'get_group_roi', // 获取团队ROI
  GET_PROFIT: 'get_profit', // 获取毛利计算
  GET_ERROR_MSGS: 'get_error_msgs', // 获取异常信息列表
  GET_DOMAIN_SKU_SALES: 'get_domain_sku_sales', // 分店铺sku销量列表
  GET_TOTAL_SKU_SALES: 'get_total_sku_sales', // 总sku销量列表
  GET_OLD_SKU_SALES: 'get_old_sku_sales', // SKU销量（旧）
  ROI_SHOW_SKU_SALES: 'roi_show_sku_sales', // ROI报表查看SKU销量
  SHOW_ROI_YOY_ANALYSIS: 'show_roi_yoy_analysis', // 团队ROI报表-同环比分析查看
  AD_REVIEWS: 'ad_reviews', // 所有广告素材
  MG_AD_REVIEWS: 'mg_ad_reviews', // 木瓜素材审核
  YN_AD_REVIEWS: 'yn_ad_reviews', // 易诺素材审核
  DEAL_STORE_STATUS: 'deal_store_status', // 修改店铺状态
  DEAL_STORE_PRIVATE_TOKEN: 'deal_store_private_token', // 更新店铺私有token
  PRODUCT_IMPORT: 'product_import', // 商品管理
  GET_STORES_ORDERS: 'get_stores_orders', // 订单汇总
  GET_RATE: 'get_rate', // 汇率查询
  DOWNLOAD_ORDERS: 'download_orders', // 导出订单汇总excel
  DEAL_ERROR_MSG: 'deal_error_msg', // 异常处理
  UPDATE_FULFILLMENTS: 'update_fulfillments', // 订单物流管理
  DEAL_AD_REVIEWS: 'deal_ad_reviews', // 广告素材
  SEARCH_AD_OPTION: 'search_ad_option', // 广告素材搜索广告账户
  SHOW_AD_OPTION: 'show_ad_option', // 广告素材显示广告账户
  ORDER_DETAILS: 'order_details', // 订单详情
  CHARGE_AD_ACCOUNT: 'charge_ad_account', // 广告户充值
  LOW_AMOUNT_RECHARGE: 'low_amount_recharge', // 低额度充值
  SHOW_SENSITIVE_AD_INFO: 'show_sensitive_ad_info', // 广告户审核模块敏感信息
  RESET_AD_ACCOUNT: 'reset_ad_account', // 广告户清零操作
  APPLY_AD_ACCOUNT: 'apply_ad_account', // 申请广告账户操作，资产关系->添加账户权限
  DEAL_APPLY_AD_ACCOUNT: 'deal_apply_ad_account', // 修改广告账户(申请页)操作
  ASSIGN_AD_ACCOUNT: 'assign_ad_account', // 预分配广告户，账户资源组 - 分配账户
  GET_APPLICATION_LIST: 'get_application_list', // 获取广告户申请列表
  GET_ACCOUNT_APPLICATION_LIST: 'get_account_application_list', // 获取广告户申请列表，账户资源组 - 获取主页申请列表
  GET_CHARGE_APPLICATION_LIST: 'get_charge_application_list', // 获取广告户充值列表
  GET_CLEAR_APPLICATION_LIST: 'get_clear_application_list', // 获取广告户清零列表
  SEND_COMPANY_NOTIFICATION: 'send_company_notification', // 发送公司全员通知
  SEND_PART_NOTIFICATION: 'send_part_notification', // 发送部门通知
  SEND_USER_NOTIFICATION: 'send_user_notification', // 发送人员通知
  REVIEW_NOTIFICATION: 'review_notification', // 审核通知
  SKU_CALC_IMPORT: 'sku_calc_import', // sku导入excel
  GROSS_MODULE_DEBUG: 'gross_module_debug', // 毛利DEBUG权限
  SKU_ORDER_ATTR_LOG: 'sku_order_attr_log', // 查看属性变更情况
  PEOPLE_SITUATION_ORDER_SHOW: 'people_situation_order_show', // 运营订单数查看
  GET_STORE_PROFIT: 'get_store_profit', // 查看店铺维度数据
  GROSS_SHOW_STORE: 'gross_show_store', // 店铺查询
  GET_ASSET: 'get_asset', // 获取资产关系列表
  APPLY_AD_PAGE: 'apply_ad_page', // 资产关系->添加主页权限
  GET_PAGE_APPLY_LIST: 'get_page_apply_list', // 账户资源组 - 获取主页申请列表
  DEAL_AD_PAGE: 'deal_ad_page', // 账户资源组 - 创建page/绑定店铺按钮
  ASSIGN_VPS: 'assign_vps', // 账户资源组 - 分配VPS按钮
  SKU_REVIEW_EXPORT: 'sku_review_export', // SKU管理列表导出excel
  ASSIGN_VPS_BK: 'assign_vps_bk', // 账户资源组 - 分配备用VPS按钮
  GET_WSHOP_STORE: 'get_wshop_store', // wshop店铺相关
  GET_MSHOP_STORE: 'get_mshop_store', // mshop店铺相关
  GET_STORE_IP: 'get_store_ip', // 店铺管理 ip 相关
  GET_XSHOPPY_STORE: 'get_xshoppy_store', // xshoppy店铺相关
  LOGISTICS_QUERY: 'logistics_query', // 物流查询页面
  LOGISTICS_INFO_EXCEL_EXPORT: 'logistics_info_excel_export', // 物流查询导出excel
  GET_SHOPBASE_STORE: 'get_shopbase_store', // shopbase店铺相关
  GET_PART_FAIL_AD_REVIEWS: 'get_part_fail_ad_reviews', // 查看部门不通过素材审核
  BM_ADMIN: 'bm_admin',
  APPLY_PIXEL: 'apply_pixel', // 资产管理 - 创建像素
  DEAL_PIXEL_APPLY: 'deal_pixel_apply', // 账户资源组 - 创建像素
  GET_ACCOUNT_APPEALS: 'get_account_appeals', // 账户申诉信息
  DEAL_ACCOUNT_APPEALS: 'deal_account_appeals', // 更新账户申诉信息
  EXPORT_ACCOUNT_APPEALS: 'export_account_appeals', // 账户申诉导出excel
  GET_WECHAT_USER: 'get_wechat_user', // 小程序用户绑定
  GET_MB_NAME: 'get_mb_name', // 获取马帮名称
  ABOLISH_PAGE_APPLY: 'abolish_page_apply', // 账户资源组 - 拒绝分配
  EXPORT_STORE_EXCEL: 'export_store_excel', // 导出店铺
  ASSIGN_BM: 'assign_bm', // 分配BM
  STORE_CHANGE_HISTORY: 'store_change_history', // 店铺变更
  SUB_AD_ACCOUNT_OR_DATA_DIFF: 'sub_ad_account_or_data_diff', // 广告账号/数据差异,
  GET_AD_POST_LINK_URL: 'get_ad_post_link_url', // 素材审核 - 查看着陆页/帖子
  // 处理工单提交异常相关权限
  SUB_STORE_ACCOUNT_OR_DATA_DIFF: 'sub_store_account_or_data_diff', // 店铺账号/数据差异,
  SUB_ORDER_OR_DATA_DIFF: 'sub_order_or_data_diff', // 订单详情/数据差异
  SUB_BM_IS_BLOCKED: 'sub_bm_is_blocked', // BM被封,
  SUB_PAGE_RATING_APPEAL: 'sub_page_rating_appeal', // page评分申诉,
  SUB_AD_DISAPPROVAL_APPEAL: 'sub_ad_disapproval_appeal', // 广告拒登申诉,
  SUB_AD_ACCOUNT_APPEAL: 'sub_ad_account_appeal', // 广告账号申诉,
  SUB_ACCOUNT_ALLOCATION_ABNORMAL: 'sub_account_allocation_abnormal', // 账号分配异常,
  SUB_PAGE_ALLOCATION_EXCEPTION: 'sub_page_allocation_exception', // page分配异常,
  SUB_PIXEL_ALLOCATION_ABNORMAL: 'sub_pixel_allocation_abnormal', // 像素分配异常,
  SUB_BROWSER_TOOL_EXCEPTION: 'sub_browser_tool_exception', // 浏览器工具异常,
  SUB_SYSTEM_EXCEPTION: 'sub_system_exception', // 系统异常,
  SUB_OTHER: 'sub_other', // 其他,
  SHOW_COMPANY: 'show_company',
  SHOW_PART: 'show_part',
  SHOW_PEOPLE: 'show_people',
  MANAGE_ERROR_MSG_COLUMN: 'manage_error_msg_column', // 管理异常信息是否显示处理人列
  ERROR_TICKET_MENU: 'error_ticket_menu', // 工单提交
  SUBMIT_ERROR_MSG: 'submit_error_msg', // 提交删除更新工单
  HELP_MANUAL: 'help_manual', // 帮助手册
  MESSAGE_CENTER: 'message_center', // 消息中心
  GET_OFFICE_USER: 'get_office_user', // 公众号用户绑定
  PLATFORM_ANALYSIS: 'platform_analysis', // 访问平台分析
  ABOLISH_PIXEL_APPLY: 'abolish_pixel_apply', // 像素拒绝创建
  DEAL_BM: 'deal_bm', // 更换BM
  DELETE_ACCOUNT_APPLY: 'delete_account_apply', // 账号资源组 - 删除
  TRANS_ASSET: 'trans_asset', // 资产转移
  UPDATE_PROFIT_SALE_ORDER_NUM: 'update_profit_sale_order_num', // 毛利重置营业额和订单数
  REFRESH_PROFIT: 'refresh_profit', // 重新计算毛利
  ACCOUNT_RECHARGE_ADMIN: 'account_recharge_admin', // 账户充值管理员权限
  DEAL_EMPLOYEE_EMAIL: 'deal_employee_email', // 新增/更换运营邮箱权限
  DEAL_ADMIN_EMAIL: 'deal_admin_email', // 新增/更换管理员邮箱权限
  SEARCH_TS_SKU: 'search_ts_sku', // 搜索真实sku
  SEARCH_VS_SKU: 'search_vs_sku', // 搜索虚拟sku
  GET_AD_REVIEW_LOG: 'get_ad_review_log', // 审核日志按钮权限
  SKU_CATEGORY_PRODUCT_STATISTICS: 'sku_category_product_statistics', // 分类产品数汇总
  SKU_PRODUCT_STATISTICS: 'sku_product_statistics', // 产品数汇总
  SKU_BATCH_SEARCH: 'sku_batch_search', // sku批量搜索
  DEAL_CLOSE_AD_POLICY: 'deal_close_ad_policy', // 关闭广告 - 策略配置
  CLOSE_ACCOUNT_AD: 'close_account_ad', // 关闭广告 - 立即关闭
  GET_CLOSE_AD_LOG: 'get_close_ad_log', // 账户策略 - 日志查看
  GET_CLOSE_AD_INFO: 'get_close_ad_info', // 账户策略 - 关闭广告
  SHOW_PART_PEOPLES: 'show_part_peoples', // 运营人员权限
  GET_ASSET_APPROVE_REPORT: 'get_asset_approve_report', // 统计报表 - 审批报表
  GET_ACCOUNT_APPEAL_REPORT: 'get_account_appeal_report', // 统计报表 - 申诉报表
  GET_BOTTOM_TEN: 'get_bottom_ten', // Dashboard后十位订单量排行榜和ROI排行榜
  GET_TOP_TEN_ROI: 'get_top_ten_roi', // Dashboard 前十位ROI排行榜
  ORDER_COUNT_BY_PART: 'order_count_by_part', // Dashboard分部门订单数
  GET_STORE_STATUS: 'get_store_status', // Dashboard店铺状态分布
  SKU_AND_ATTR_NAME_COLUMN: 'sku_and_attr_name_column', // 显示子SKU名称，产品属性，属性中文名称列
  PP_ACTUAL_AMOUNT: 'pp_actual_amount', // pp信息 - 实时余额
  DEAL_OLD_BM_CHANGE_EMAIL: 'deal_old_bm_change_email', // 更换个人号
  PROFIT_REFUND_COLUMN: 'profit_refund_column', // 毛利核算退款列
  PROFIT_PLATFORM_OPERATION_FEE_COLUMN: 'profit_platform_operation_fee_column', // 部门总平台手续费/订单操作费列
  GET_COUNTRY_SKU_CALC: 'get_country_sku_calc', // SKU国家核算
  PURCHASER_REMARK_COLUMN: 'purchaser_remark_column', // 采购产品备注列
  HAS_ORDER_SELECT: 'has_order_select', // 是否出单筛选框
  OPERATION_COLUMN_AND_SELECT: 'operation_column_and_select', // 运营列及人员搜索
  GET_BM_CHANGE_LIST: 'get_bm_change_list', // 审核中心 - 更换BM申请
  GET_BM_REPORT: 'get_bm_report', // 统计报表 - BM统计
  STORE_ORDER_DATE_TREND: 'store_order_date_trend', // 店铺订单日期销量趋势
  GET_EMAIL_AND_PASSWORD: 'get_email_and_password', // 店铺管理筛选邮箱和密码
  GET_STATUS_AND_DATE: 'get_status_and_date', // 素材审核初始状态和日期
  GET_ERROR_MSG_REPORTT: 'get_error_msg_report', // 异常工单报表
  STORE_MIGRATE: 'store_migrate', // 商品管理 - 整站产品迁移
  FILTER_APP_INSTALLED_STATUS: 'filter_app_installed_status', // 过滤备用店铺
  TAX_TOTAL_COLUMN: 'tax_total_column', // 显示交易税信息
  DELETE_USER: 'delete_user', // 用户管理 - 删除用户
  ORDER_COMPARE_ANALYSIS: 'order_compare_analysis', // 对比分析
  GET_API_EMAIL: 'get_api_email', // 资产关系 - API邮箱
  GET_GOOGLE_ANALYTICS: 'get_google_analytics', // 谷歌分析 - 获取GA数据
  AUTH_GOOGLE_ANALYTICS: 'auth_google_analytics', // 谷歌分析 - 授权GA数据
  DEAL_STORE_META: 'deal_store_meta', // 店铺管理 - 扩展属性
  COPY_COOKIE_AND_URL: 'copy_cookie_and_url', // 资产关系 - 复制对应个人号的cookie以及配置文件URL
  CPA_ANALYSIS: 'cpa_analysis', // CPA分析
  WSHOP_ADD_CART_ANALYSIS: 'wshop_add_cart_analysis', // wshop 加购数据报表
  GET_STORE_PEOPLE_CHANGE: 'get_store_people_change', // 店铺管理 - 人员变更tab
  WSHOP_ADD_CART_DATA_EXCEL_EXPORT: 'wshop_add_cart_data_excel_export', // wshop 加购数据 - 导出excel
  DEAL_DOUBLE_VERIFY: 'deal_double_verify', // 资产关系 - 双重验证
  GET_NS_SHOP: 'get_ns_shop', // NS 平台店铺,
  VISIT_CLIP_TASK: 'visit_clip_task', // 视频派单-查看
  CREATE_CLIP_TASK: 'create_clip_task', // 视频派单-新任务-评价
  GET_CLIP_TASK: 'get_clip_task', // 视频派单-接单
  GET_STORE_PAYPAL: 'get_store_paypal', // 店铺PP账号
  BIND_PAYPAL_PAYPMENT: 'bind_paypal_payment', // 店铺绑定paypal支付
  CPM_ANALYSIS: 'cpm_analysis', // CPM分析
  ASSET_MANDATE_TO_EMAIL: 'asset_mandate_to_email', // 次产关系 - 资产授权到邮箱
  SKU_STATISTIC_EXCEL_EXPORT: 'sku_statistic_excel_export', // 产品数汇总/分类产品数汇总 Excel导出
  DOMAIN_PP_CHANGE_LIST: 'domain_pp_change_list', // 域名PP变更记录
  SKU_PRODUCT_CHECK_STATISTICS: 'sku_product_check_statistics', // 开发流程的产品审核数据统计
  PP_HEALTH_EXAMINATION: 'pp_health_examination', // 健康检查
  SHOW_PP_EMAIL_COLUMN: 'show_pp_email_column', // 健康检查显示paypal邮箱
  REPLACE_SPECIFIED_BM: 'replace_specified_bm', // 资产关系 - 更换至BM
  BIND_WORLDPAY_PAYMENT: 'bind_worldpay_payment', // 店铺绑定worldpay支付
  CREDIT_CARD_PAYMENT_COLUMN: 'credit_card_payment_column', // 信用卡收款列
  CREDIT_CARD_NUMBER_COLUMN: 'credit_card_number_column', // 信用卡账号列
  SHOW_PP_LIST: 'show_pp_list', // 显示pp列表
  DEAL_PP_LIST: 'deal_pp_list', // 增删改PP列表
  APPEAL_FORBID_ORGAN: 'appeal_forbid_organ', // 账号申诉 - 禁止部门人员组织信息
  SKU_ONLY_SELF_DATA: 'sku_only_self_data', // 产品审核仅显示自己提交记录
  DEAL_OLD_BM_CHANGE: 'deal_old_bm_change', // 审核中心 - 旧系统移户申请
  DEAL_EMAIL_DOUBLE_VERIFY: 'deal_email_double_verify', // 审核中心 - 双重验证
  GET_EMAIL_SECRET_INFO: 'get_email_secret_info', // 审核中心 - 个人号密钥查询
  SHOW_ADMIN_AND_CUSTOMER_SERVICE_EMAIL: 'show_admin_and_customer_service_email', // 店铺管理-显示账户和客服邮箱
  UPDATE_ADMIN_AND_CUSTOMER_SERVICE_EMAIL: 'update_admin_and_customer_service_email', // 店铺管理-更新账户和客服邮箱
  SEARCH_ORDERS_LIST: 'search_orders_list', // 查询订单
  BM_API_CHANGE: 'bm_api_change', // 审核中心 - 更换BM API账号
  AD_ACCOUNT_TRANSFER: 'ad_account_transfer', // 资产关系 - 广告户转移
  DEAL_PIXEL_UNASSIGN: 'deal_pixel_unassign', // 资产关系 - 解除像素绑定
  POST_DOWNLOAD_FAIL_HIDE: 'post_download_fail_hide', // 素材下载失败隐藏
  POST_OPERATOR_SEARCH: 'post_operator_search', // 素材操作人查询
  TRACK_ORDER_IMPORT: 'track_order_import', // 物流信息导入
  BIND_XBORDERPAY_PAYMENT: 'bind_xborderpay_payment', // 店铺绑定xborderpay支付
  GET_SKU_OPERATE_LOG: 'get_sku_operate_log', // 获取产品审核操作日志
  UPDATE_CURRENCY_AND_TIMEZONE: 'update_currency_and_timezone', // 店铺管理 - 修改wshop店铺时区和货币
  DEAL_XB_LIST: 'deal_xb_list', // 增删改xborderpay
  DEAL_WP_LIST: 'deal_wp_list', // worldpay
  DOWNLOAD_ORDER_PAYMENT_ANALYSIS: 'download_order_payment_analysis', // 订单收款渠道占比分析excel下载
  SHOW_XB_LIST: 'show_xb_list', // 显示XB账号管理
  SHOW_WP_LIST: 'show_wp_list', // 显示WP账号管理
  ASSIGN_CLIP_TASK: 'assign_clip_task', // 素材派单转出按钮
  ON_OFFER_SKU_MANAGE: 'on_offer_sku_manage', // sku管理 - 在售SKU
  UN_SHELVE_SKU: 'un_shelve_sku', // SKU管理 - SKU下架日志
  UN_SHELVE_SKU_LOG_EXPORT: 'un_shelve_sku_log_export', // sku下架日志 - 导出excel
  SUB_PIXEL_DATA_ABNORMAL: 'sub_pixel_data_abnormal', // 像素数据异常
  GET_ASSET_CONSUMPTION_REPORT: 'get_asset_consumption_report', // 统计报表 - 资产消耗
  CHANGE_AD_ACCOUNT_NAME: 'change_ad_account_name', // 广告户管理 - 修改广告账号名称
  SKU_CALC_EXPORT: 'sku_calc_export', // sku核算 - 导出sku核算数据
  SKU_GROSS_MARGIN_EXPORT: 'sku_gross_margin_export', // 毛利核算 - 按天导出Excel
  STOCK_SKU_SEARCH: 'stock_sku_search', // SKU管理 - 库存SKU
  ON_OFFER_SKU_SALES: 'on_offer_sku_sales', // 在售sku - 查看在售sku销量数据
  ON_OFFER_SKU_EXPORT: 'on_offer_sku_export', // 在售SKU - 导出excel
  VIRTUAL_REAL_TRACK_SEARCH: 'virtual_real_track_search', // 物流管理 - 虚拟单号管理
  VIRTUAL_REAL_TRACK_EXPORT: 'virtual_real_track_export', // 虚拟单号管理 - 导出excel
  AD_ACCOUNT_INFO_EXPORT: 'ad_account_info_export', // 广告户管理 - 导出excel
  BM_LEVEL_SEARCH: 'bm_level_search', // BM属性搜索
  DEAL_BM_LEVEL: 'deal_bm_level', // 更新BM属性
  DEAL_BM_PROXY_PORT: 'deal_bm_proxy_port', // 端口变更日志 更新代理端口
  STORE_TRACKING_INFORMATION: 'store_tracking_information', //  店铺追踪信息
  UPDATE_FB_DOMAIN_VERIFICATION: 'update_fb_domain_verification', // wshop店铺FB域名验证设置
  WS_PRODUCT_CONVERSION_LIST: 'ws_product_conversion_list', // WS转化率分析(新)
  MODIFY_SKU_REVIEW: 'modify_sku_review', // 修改产品审核
  STORE_CHECKOUT_INFOMATION: 'store_checkout_information', // 店铺信息 - 收款结账信息
  SKU_REVIEW_TEAM_PEOPLE: 'sku_review_team_people', // 产品审核普通组员
  SKU_REVIEW_TEAM_LEADER: 'sku_review_team_leader', // 产品审核组长
  PP_DISPUTE_LIST: 'pp_dispute_list', // pp争议列表
  ASSET_RECYCLE_APPLY: 'asset_recycle_apply', // 资产解绑
  STOCK_SKU_MANAGE: 'stock_sku_manage', // 库存sku管理
  BIND_PPC_PAYPMENT: 'bind_ppc_payment', // 店铺绑定ppc支付
  SKU_CHECK_TIME_STATISTICS: 'sku_check_time_statistics', // 操作时长汇总
  GET_SKU_INFRINGEMENT_LIST: 'get_sku_infringement_list', // 侵权产品列表
  UPDATE_SKU_INFRINGEMENT: 'update_sku_infringement', // 更新侵权商品
  DEAL_DOMAIN_LIST: 'deal_domain_list', // 新增、更新域名
  TRACKING_HUB_SEARCH: 'tracking_hub_search', // 查询trackhub单号
  TRACKING_HUB_EXPORT: 'tracking_hub_export', // trackhub单号Excel导出
  DEAL_SUGGEST_LIST: 'deal_suggest_list', // 新增、更新建议
  SKU_REVIEW_STOCK_AND_QUANTITY: 'sku_review_stock_and_quantity', // 显示库存数和在途数
  GET_LANDING_PAGE_LIST: 'get_landing_page_list', // 落地页审核
  GET_RECOMMEND_PRODUCT_LIST: 'get_recommend_product_list', // 供应链系统提供的推荐商品
  VIEW_ASSET_REVIEW: 'view_asset_review', // 资产审核  查看
  REVIEW_ASSET_REVIEW: 'review_asset_review', // 资产审核  审核
  MODIFIED_ASSET_REVIEW: 'modified_asset_review', // 资产审核  审核
  DOMAIN_SOURCE_SHOP: 'domain_source_shop', // 域名管理 - 店铺
  DOMAIN_SOURCE_EXTERNAL: 'domain_source_external', // 域名管理 - 外部
  DOMAIN_SOURCE_INSIDE: 'domain_source_inside', // 域名管理 - 内部
  SHOW_STORE_META_COL: 'show_store_meta_col', // 显示店铺额外配置
  REPLY_SUGGEST_LIST: 'reply_suggest_list', // 处理人回复建议
  TOOL_TEXT2IMAGE: 'tool_text2image', // 文字转图片
  GET_ASSET_APPLY_ASSIGN: 'get_asset_apply_assign', // 账号分配
  GET_ASSET_APPLY_RECYCLE: 'get_asset_apply_recycle', // 账号回收
  GET_ASSET_PAGE_APPLY: 'get_asset_page_apply', // page创建
  GET_ASSET_PAGE_RECYCLE: 'get_asset_page_recycle', // page回收
  GET_ASSET_PIXEL_APPLY: 'get_asset_pixel_apply', // 像素创建
  GET_ASSET_PIXEL_RECYCLE: 'get_asset_pixel_recycle', // 像素回收
  GET_ASSET_APPLY: 'get_asset_apply', // 全有权限
  GET_HERMES_USERS_LIST: 'get_hermes_users_list', // 爱马仕-用户管理中心
  APPLY_AD_PAGE_REMARK: 'apply_ad_page_remark', // page创建 修改备注
  SUBMIT_STORE_TICKET: 'submit_store_ticket', // 提交店铺工单
  DEAL_STORE_TICKET: 'deal_store_ticket', // 处理店铺工单
  DEAL_PROMOTE_STOCK_SKU: 'deal_promote_stock_sku', // 增删改激励库存SKU
  CUSTOMIZE_PRODUCT_DETAIL: 'customize_product_detail', // 定制产品详情
  CHECK_PRODUCT: 'check_product', // 产品审核更新产品审核
  AD_PROVIDER_OWNED_VPS_COL: 'ad_provider_owned_vps_col', // 所属PC及VPS
  CUSTOMIZE_CATE_ONLY_CREATE_DEL: 'customize_cate_only_create_del', // 自定义分类增删
  CUSTOMIZE_CATE_NOT_CREATE_DEL: 'customize_cate_not_create_del', // 自定义分类增删
  BIND_PP_REST_PAYMENT: 'bind_pp_rest_payment',
  GET_DNS_LIST: 'get_dns_list', // 查看DNS列表
  DNS_UPDATE_DEL: 'dns_update_del', // 删除和修改DNS列表
  VIEW_PAGES_LIST: 'view_pages_list', // 查看pages审核
  VIEW_LANDING_LIST: 'view_landing_list', // 查看网站审核
  VIEW_DOMAINS_LIST: 'view_domains_list', // 	查看着陆页审核
  ASSET_REVIEW_SEARCH: 'asset_review_search', // 查看部门/人员/审核人
  EXPORT_PRODUCTS: 'export_products', // 批量导出定制产品
  ASSIGN_AD_ACCOUNT_STATUS: 'assign_ad_account_status', // 更新分配账号工单状态
  PRODUCT_TEST_LIST: 'product_test_list', // 查看测试产品
  PRODUCT_TEST_EDIT: 'porduct_test_edit', // 编辑测试产品
  PRODUCT_TEST_APPLICATION: 'porduct_test_application', // 申请测试产品
  LIMIT_CHARGE_AD_ACCOUNT: 'limit_charge_ad_account',
  PRODUCT_TEST_SALES: 'porduct_test_sales', // 查看测试产品销量
  SHOW_AD_SPEND: 'show_ad_spend', // 显示广告花费
  CHANGE_EMAIL_PWD: 'change_email_pwd', // 修改个人号密码
  PIXEL_BELONG_INQUIRE: 'pixel_belong_inquire', // 像素归属查询
  ORDER_GROUP_PAYMENT_ANALYSIS: 'order_group_payment_analysis', // 收款渠道分析
  WS_ABANDONED_LIST: 'ws_abandoned_list', // 弃单分析
  BLOCK_DOMAINS: 'block_domains', // 域名黑名单
  SKU_REVIEW_MB_SUPPLY: 'sku_review_mb_supply', // 马帮供应商信息
  INVENTORY_DETAILS: 'inventory_details', // 操作资产盘点
  SHOW_BM_AND_AGENT_COL: 'show_bm_and_agent_col', // 展示BM和代理商
  UPDATE_PIXELS: 'update_pixels', // 更新Token
  EXPORT_ALL_PEOPLE_ROI: 'export_all_people_roi', // 	  导出全部人员ROI
  EXPORT_ALL_PEOPLE_PROFIT: 'export_all_people_profit', // 	  导出全部人员毛利
  DEAL_SKU_DAILY: 'deal_sku_daily',
  SUB_PIXEL_TOKEN: 'sub_pixel_token', // 获取像素token
  SKU_REVIEW_SUBORDINATE_DATA: 'sku_review_subordinate_data',
  EXCEL_PERFORMANCE_RANK: 'excel_performance_rank',
  BM_APPLICATION_APPROVE: 'bm_application_approve', // BM申请审批
  CREATE_GOOGLE_AD_ACCOUNTS: 'create_google_ad_accounts', // 新增谷歌账号
  BM_APPLICATION: 'bm_application', // BM申请
  ADVANCED_AD_ACCOUNT_APPLY: 'advanced_ad_account_apply', // 申请单个账户
  GET_GROSS_MARGIN_BONUS: 'get_gross_margin_bonus',
  SUBMIT_BM_APPLICATION: 'submit_bm_application', // 工单提交BM申请独立提交
  MODIFY_AD_ACCOUNT_BALANCE: 'modify_ad_account_balance', // 修改余额
  SKU_DAILY_SCORING: 'sku_daily_scoring', // sku工作日报打分
  CREATE_GOOGLE_EMAIL: 'create_google_email', // 授权新邮箱
  GOOGLE_ACCOUNT_SPEND: 'google_account_spend',
  CUTOVER_STORE_PP: 'cutover_store_pp', // 一键切换店铺pp
  GET_GROSS_MARGIN_TREND: 'get_gross_margin_trend',
  GROSS_INSERT_DEDUCT_DATA: 'gross_insert_deduct_data', // 毛利核算导入
  EXCEL_UPDATE_STORE_PP: 'excel_update_store_pp', // 批量修改日志权限
  LANDING_PAGE_EXPORT: 'landing_page_export', // 落地页审核导出
  LANDING_PAGE_CONFIG: 'landing_page_config', // 数据抓取配置
  ROI_PIXEL_TRACK: 'roi_pixel_track', // 像素追踪
  GET_ONE_YEAR_PROFIT: 'get_one_year_profit', // 毛利核算筛选全年权限
  DEAL_STOCK_SKU: 'deal_stock_sku', // 操作库存产品
  ADMIN_UPDATE_STORE_PAYPAL: 'admin_update_store_paypal', // 管理员修改店铺PP
  SKU_SUPPLY_TOP_EXPORT: 'sku_supply_top_export', // sku销量供应链按钮
  SEE_ALL_DISPUTE_INFO: 'see_all_dispute_info', // 查看争议明细
  REVIEW_RECOMMEND_PRODUCT: 'review_recommend_product', // 供应商推品审批
  SKU_REVIEW_STORE_PUBLIC_URL: 'sku_review_store_public_url', // 展示店铺商品链接
  UPDATE_STORE_STATUS_WHEN_ITS_RECYCLE: 'update_store_status_when_its_recycle', // 修改回收的店铺状态
  ASSIGN_RELIABLE_CREATED_PAGE: 'assign_reliable_created_page',
  ACT_APPLY_REMARK: 'act_apply_remark',
  SALE_SKU_PRODUCT_CHECK: 'sale_sku_product_check', // 产品审核
  SALE_SKU_LANDING_CHECK: 'sale_sku_landing_check', // 落地页审核
  SALE_AFTER_AFTER_REVIEW_URL: 'sale_after_review_url',
  SALE_SKU_SHIP_CHECK: 'sale_sku_ship_check', // 发货审核
  SALE_SKU_REHEAR_CHECK: 'sale_sku_rehear_check', // 提交复审
  SALE_SKU_ONLY_VIEW: 'sale_sku_only_view', // 售后审核查看
  PART_CUSTOM_SUMMARY: 'part_custom_summary', // 部门自定义汇总
  SPECIAL_PART_SUMMARY: 'special_part_summary', // 特殊部门汇总
  SKU_RERUN: 'sku_rerun', // sku核算重算
  SKU_DISPUTE_LIST: 'sku_dispute_list', // SKU争议
  ASSET_LIST_EXPORT: 'asset_list_export', // 资产关系导出
  SALE_SKU_COMPLAINT_CHECK: 'sale_sku_complaint_check', // 产品售后审核-投诉率tab
  SALE_ADTER_REVIEW_DISPUTE: 'sale_after_review_dispute',
  SHOW_BM_EMAIL_AUTH_INFO: 'show_bm_email_auth_info', // 查看BM授权邮箱
  PP_REST_TR_CONFIG_MANAGER: 'pp_rest_tr_config_manager', // 授权配置按钮
  STOCK_UP_APPLICATION_INDEX: 'stock_up_application_index', // 查看运营申请备货
  STOCK_UP_APPLICATION_SUBMISSION: 'stock_up_application_submission', // 申请备货
  STOCK_UP_APPLICATION_REVIEW: 'stock_up_application_review', // 备货申请审核(部门审核)
  VIEW_ONLY_PAYPAL: 'view_only_paypal',
  AFTER_SALE_CHECK_SHOW_THE_DELIVERY_RATE: 'after_sale_check_show_the_delivery_rate',
  SHOW_SHIPPED_RATE: 'show_shipped_rate',
  STOCK_UP_APPLICATION_UPDATE_DAILY_SUPPLY: 'stock_up_application_update_daily_supply', // 更新日供应量
  ESTIMATED_FINAL_COMPLAINT_RATE: 'estimated_final_complaint_rate', // 预估最终投诉率
  ESTIMATED_FINAL_ORDER_COMPLAINT_RATE: 'estimated_final_order_complaint_rate', // 预估最终投诉订单率
  FULL_FB_RESOURCE_ASSIGN: 'full_fb_resource_assign', // 整套资产分配
  BIND_PPC_REST_PAYMENT: 'bind_ppc_rest_payment', // 绑定PPCR
  VS_SKU_CHECK_TIME: 'vs_sku_check_time', //采购审核时效
  PURCHASE_COST_COMPARISON: 'purchase_cost_comparison', // 采购成本对比
  SKU_ORDER_INFO: 'sku_order_info', // 订单详情权限
  AFTER_SALE_CHECK_EXPORT: 'after_sale_check_export', //导出产品售后审核记录
  EXPORT_ALL_PEOPLE_DISPUTE: 'export_all_people_dispute', // 导出全员争议数据
  STOCK_UP_APPLICATION_INTERNAL_REVIEW: 'stock_up_application_internal_review', //备货申请审核(部门审核)
  STOCK_UP_APPLICATION_COORDINATION_REVIEW: 'stock_up_application_coordination_review', //备货申请审核(统筹审核)
  STOCK_UP_APPLICATION_AFTERMARKET_REVIEW: 'stock_up_application_aftermarket_review', //备货申请审核(售后审核)
  STOCK_UP_PLAN_INDEX: 'stock_up_plan_index', // 查看采购备货计划
  STOCK_UP_PLAN_UPDATE: 'stock_up_plan_update', // 采购备货计划操作
  DELIVERY_REVIEW_SCHEDULE: 'delivery_review_schedule', // 投放审核排班
  PURCHASE_SUPPLY_CHAIN_UPDATE: 'purchase_supply_chain_update', // SKU采购供应链信息=>采购供应链信息操作
  PURCHASE_SUPPLY_CHAIN_TS_INFO: 'purchase_supply_chain_ts_info', // SKU采购供应链信息=>真实SKU tab
  PURCHASE_SUPPLY_CHAIN_VS_INFO: 'purchase_supply_chain_vs_info', // SKU采购供应链信息=>虚拟SKU tab
  SKU_FORK: 'sku_fork', // 产品审核 SKU复制
  PRODUCT_VIOLATION_REVIEW: 'product_violation_review', // 产品违规情况审核
  SKU_REVIEW_BATCH_IMPORT: 'sku_review_batch_import', // 批量导入产品
  SHOW_ASSET_WORK_ORDER_FAIL_REASON: 'show_asset_work_order_fail_reason', //查看资产工单失败原因及处理备注
  PP_CONFIG_RECORD_UPDATE: 'pp_config_record_update', // 修改PP参数
  DELETE_PART: 'delete_part', //删除部门
  SHOP_DOMAIN_MANAGE: 'shop_domain_manage', //店铺管理修改主域名
  ONLY_NORMAL_BM: 'only_normal_bm', // 仅查看正常BM
  USER_SHOW_SEVEN_DAYS_NOT_PLACED_PURCHASE_SKU: 'user_show_seven_days_not_placed_purchase_sku', // 普通用户查看7天未下采购SKU
  ADMIN_SHOW_SEVEN_DAYS_NOT_PLACED_PURCHASE_SKU: 'admin_show_seven_days_not_placed_purchase_sku', // 管理用户查看7天未下采购SKU
  DEAL_SEVEN_DAYS_NOT_PLACED_PURCHASE_SKU: 'deal_seven_days_not_placed_purchase_sku', // 处理7天未下采购SKU
  REVIEW_RISK_CONTROL: 'review_risk_control', //审核风控审核信息
  SUBMIT_RISK_CONTROL: 'submit_risk_control', //提交复审风控审核信息
  EXPORT_RISK_CONTROL: 'export_risk_control', //导出风控审核记录
  SKU_HOT_SALE_PASS_PAGE: 'sku_hot_sale_pass_page', // 查看审批通过SKU热销榜
  SKU_HOT_SALE_REVIEW: 'sku_hot_sale_review', //  SKU热销榜(审核)
  SKU_HOT_SALE_REVIEW_REPEAT: 'sku_hot_sale_review_repeat', //  SKU热销榜(提交复审)
  SKU_HOT_SALE_UPDATE: 'sku_hot_sale_update', //  编辑SKU热销榜
  SKU_HOT_SALE_PAGE_PUBLIC_URL: 'sku_hot_sale_page_public_url', //  查看SKU热销榜公共链接
  SKU_HOT_SALE_REVIEW_LOG: 'sku_hot_sale_review_log', //  查看SKU热销榜审核信息
  SKU_HOT_SALE_RETURN_URL_LOG: 'sku_hot_sale_return_url_log', //  查看SKU热销榜导入监测日志
  PP_EMERGENCY_OPERATION: 'pp_emergency_operation', //店铺管理PP批量操作
  PIXEL_SHARE_BMS: 'pixel_share_bms', //  像素分享BM
  STORE_ORDER_AND_EXPORT_OF_PP_SITUATION: 'store_order_and_export_of_pp_situation', // 店铺出单及pp情况导出
  SHOW_ABNORMAL_ORDER_PROCESSING: 'show_abnormal_order_processing', // 查看异常订单处理
  ABNORMAL_ORDER_PROCESSING_OPERATION: 'abnormal_order_processing_operation', // 异常订单处理（运营）
  ABNORMAL_ORDER_PROCESSING_COORDINATOR: 'abnormal_order_processing_coordinator', // 异常订单处理（统筹专员）
  ABNORMAL_ORDER_PROCESSING_DIRECTOR: 'abnormal_order_processing_director', // 异常订单处理（统筹主管）
  ABNORMAL_ORDER_NOT_REVIEW_TAB: 'abnormal_order_not_review_tab', // 异常订单未审核tab查看
  ABNORMAL_ORDER_ABNORMAL_REVIEW_TAB: 'abnormal_order_abnormal_review_tab', // 异常订单审核异常tab查看
  ABNORMAL_ORDER_FAILED_REVIEW_TAB: 'abnormal_order_failed_review_tab', // 异常订单审核失败tab查看
  ABNORMAL_ORDER_ABNORMAL_SKU_TAB: 'abnormal_order_abnormal_sku_tab', // 异常订单异常SKUtab查看
  PROFIT_ORDER_NUM_EXPORT: 'profit_order_num_export', // 月初毛利数据导出
  PROFIT_ORDER_DETAILS_EXPORT: 'profit_order_details_export', //毛利订单明细导出
  SKU_SUPPLIER_PAGE: 'sku_supplier_page', // SKU供应商列表
  SKU_SUPPLIER_UPDATE: 'sku_supplier_update', // SKU供应商列表，更新
  VIEW_REAL_EXPRESS_RATES: 'view_real_express_rates', //查看无异常订单发货率
  SKU_SALES_SPECIAL_PART_SUMMARY: 'sku_sales_special_part_summary', // SKU销量特殊汇总
  SHOW_NOT_RECEIVED_DESCRIPTION_NOT_MATCH: 'show_not_received_description_not_match', //查看未收到货及描述不符投诉数据
  AFTER_SALE_CHECK_SHOW_NOTE: 'after_sale_check_show_note', // 产品售后审核查看备注
  AFTER_SALE_CHECK_EDIT_NOTE: 'after_sale_check_edit_note', // 产品售后审核编辑备注
  CATEGORY_EXPRESS_RATE_INFO: 'category_express_rate_info', // 品类发货率
  REAL_CATEGORY_EXPRESS_RATE_INFO: 'real_category_express_rate_info', // 品类发货率（无异常）
  SHOW_INFRINGING_KEYWORDS: 'show_infringing_keywords', //查看侵权关键词
  DEAL_INFRINGING_KEYWORDS: 'deal_infringing_keywords', // 侵权关键词操作
  WRITE_SKU_POSTAL_COUNTRY_CONFIGS: 'write_sku_postal_country_configs', // 编辑物流国家配置
  LOGISTICS_SKU_FREIGHT_CONFIG: 'logistics_sku_freight_config', // 物流国家配置-物流特殊SKU配置
  FREIGHT_CALC_LOG: 'freight_calc_log', // 物流国家配置-运费试算记录
  INDEX_AUDIT_RESULTS_POST: 'index_audit_results_post', //查看审核结果总体情况（素材审核）
  INDEX_AUDIT_RESULTS_PAGE: 'index_audit_results_page', //查看审核结果总体情况（Page审核
  INDEX_AUDIT_RESULTS_DOMAIN: 'index_audit_results_domain', //查看审核结果总体情况（网站审核）
  INDEX_AUDIT_RESULTS_LANDING: 'index_audit_results_landing', //查看审核结果总体情况（着陆页审核）
  INDEX_PART_AUDIT_RESULTS: 'index_part_audit_results', //查看部门审核情况
  INDEX_PROVIDER_AUDIT_RESULTS: 'index_provider_audit_results', // 查看代理商审核情况
  AD_ACCOUNT_STATUS_CHANGE_NOTIFY: 'ad_account_status_change_notify', //广告户变更通知与标记
  ACCOUNT_AUTO_RECHARGE: 'account_auto_recharge', // 自动充值
  AD_ACCOUNT_STATISTICS_PAGE: 'ad_account_statistics_page', //查看广告账号情况分析
  AD_ACCOUNT_STATISTICS_ABNORMAL_PAGE: 'ad_account_statistics_abnormal_page', // 查看广告账号异常情况
  AD_ACCOUNT_STATISTICS_PROXY_PAGE: 'ad_account_statistics_proxy_page', // 查看代理商广告账户情况
  AD_ACCOUNT_STATISTICS_EXCEPTION_HANDLE_PAGE: 'ad_account_statistics_exception_handle_page', // 查看广告账号异常处理情况
  AD_ACCOUNT_STATISTICS_RELATED_INFO_PATE: 'ad_account_statistics_related_info_pate', // 查看关联资产审核情况
  CUSTOM_PRODUCT_ORDER_INFORMATION_PAGE: 'custom_product_order_information_page', // 查看定制产品订单信息
  VIEW_DISPUTE_AMOUNT: 'view_dispute_amount', // 查看争议金额权限
  SKU_SALES_SENSITIVES: 'sku_sales_sensitives', // 查看敏感信息
  PART_COLUMN_AND_SELECT: 'part_column_and_select', // SKU部门列及搜索
  SUMMARY_STATISTICS_USER_NAME_EDIT: 'summary_statistics_user_name_edit', //汇总统计人员姓名编辑
  ORDER_ADDRESS_VERIFICATION: 'order_address_verification', // 订单物流处理 订单地址校验
  DELETE_SKU_DETAIL: 'delete_sku_detail', //SKU删除明细
  SKU_REPLACE_SUPPLY_STORE: 'sku_replace_supply_store', //新增替换货源
  SKU_REPLACE_SUPPLY_CHECK: 'sku_replace_supply_check', //审核替换货源
  ORDER_LOGISTICS_PROCESSING: 'order_logistics_processing', // 订单物流处理 其他Tab统一权限
  BIND_THIRD_CREDIT_CARD: 'bind_third_credit_card', //绑定信用卡通道3
  THIRD_CREDIT_CARD_MANAGE: 'third_credit_card_manage', //信用卡通道3管理
  VIEW_BIP_AUDIT_PURCHASING_SOURCE: 'view_bip_audit_purchasing_source', //查看BIP审核采购货源
  WRITE_THIRD_CREDIT_CARD: 'write_third_credit_card', //编辑信用卡通道3账号
  COMPLETE_AD_SPEND_DATA: 'complete_ad_spend_data', // 完整广告花费数据
  DELETE_AD_ACCOUNT_REGISTER_INFO: 'delete_ad_account_register_info', //删除广告账号开户登记
  EDIT_AD_ACCOUNT_SUBMIT_INFO: 'edit_ad_account_submit_info', //编辑广告账号提交信息
  AD_FB_AD_ACCOUNT_TIMEZONE: 'ad_fb_ad_account_timezone', // 广告账号时区
  USER_DOWNLOAD_TASK_CHECK_SKU_RECORD: 'user_download_task_check_sku_record', //SKU审核记录统计
  USER_DOWNLOAD_TASK_UNCHECK_PART_ORDER: 'user_download_task_uncheck_part_order', //部门统计已出单未审核SKU数量
  USER_DOWNLOAD_TASK_TEAM_MABAN_ORDER: 'user_download_task_team_maban_order', //部门统计已出单未审核SKU数量
  USE_DOWNLOAD_TASK_THURSDAY_MABAN_ORDER: 'user_download_task_thursday_maban_order', //每周五导出马帮的订单数据
  USE_DOWNLOAD_ORDERS_OF_OVER_CHECK_DAY: 'user_download_orders_of_over_check_day', //审核时效超过24小时订单明细
  USER_DOWNLOAD_TASK_MONTH4_MABAN_ORDER: 'user_download_task_month4_maban_order', //每月4日导出马帮订单数据
  USER_DOWNLOAD_TASK_MONTH4_MA_BANG_EXPRESS: 'user_download_task_month4_ma_bang_express', //每月4日导出马帮发货数据
  USER_DOWNLOAD_TASK_MONTH4_MA_BANG_DETAIL: 'user_download_task_month4_ma_bang_order_detail', //每月4日导出马帮订单金额数据
  USER_DOWNLOAD_TASK_DAILY_SPLIT_ORDER: 'user_download_task_export_daily_split_order', //每日导出拆分单
  MONTH_PAID_ORDER: 'month_paid_order', // 每月导出发货数据
  MONTH_HOT_SALE: 'month_hot_sale', // 每周一导出前一月热销商品top500
  MONTH_FREIGHT: 'month_freight', // 月发货运费统计导出
  USER_DOWNLOAD_TASK_DAILY_ADDRESS: 'user_download_task_daily_address', // 每日纯数字邮寄地址导出
  DOWNLOAD_LANDING_PAGE_ANALYSIS: 'download_landing_page_analysis', // 落地页分析报表
  VIEW_ASSOCIATED_PEOPLE_SKU: 'view_associated_people_sku', // 仅查询关联人员SKU
  VIEW_STOP_SKU_RECORD: 'view_stop_sku_record', // 查看有库存SKU停复工信息
  EDIT_STOP_SKU_RECORD: 'edit_stop_sku_record', // 操作SKU停复工信息
  VIEW_SKU_VOID_ORDER_SUMMARY: 'view_sku_void_order_summary', // 查看SKU作废订单汇总
  RECOVER_VOID_ORDER: 'recover_void_order', // 恢复作废单
  VIEW_VOID_ORDER_INFO: 'view_void_order_info', //  查看作废订单信息
  READ_POST_INFRINGEMENTS: 'read_post_infringements', // 查看素材侵权管理
  WRITE_POST_INFRINGEMENTS: 'write_post_infringements', // 素材侵权二审
  VIEW_LOGISTICS_FREIGHT_STATISTICS: 'view_logistics_freight_statistics', //查看物流运费统计
  OPERATION_LOGISTICS_FREIGHT_STATISTICS: 'operation_logistics_freight_statistics', //操作物流运费统计
  READ_SKU_INFRINGEMENTS: 'read_sku_infringements', // 查看产品侵权管理
  WRITE_SKU_INFRINGEMENTS: 'write_sku_infringements', // 素材产品二审
  EDIT_SKU_STOCK_PEOPLE: 'edit_sku_stock_people', // 编辑库存产品.共享人员
  EDIT_SKU_STOCK_PART: 'edit_sku_stock_part', // 编辑库存产品.共享部门
  DEAL_TAKE_DOWN_REVIEW: 'deal_take_down_review', //操作BIP审核下架
  READ_CHECK_ASSET_INFRINGEMENTS: 'read_check_asset_infringements', // 查看风控侵权管理
  WRITE_CHECK_ASSET_INFRINGEMENTS: 'write_check_asset_infringements', // 风控侵权二审
  READ_ASSET_INFRINGEMENTS: 'read_asset_infringements', // 查看资产侵权管理
  WRITE_ASSET_INFRINGEMENTS: 'write_asset_infringements', // 资产侵权二审
  VIEW_SAMPLING_MATERIAL: 'view_sampling_material', // 查看资产抽检审核-素材
  CREATE_SAMPLING_MATERIAL_LOG: 'create_sampling_material_log', // 素材抽检审核
  CREATE_SAMPLING_MATERIAL_RULE: 'create_sampling_material_rule', // 抽检设置
  SAMPLING_USER: 'sampling_user', // 审核人员配置
  SHOW_POST_ID: 'show_post_id', // 查看抽检审核帖子ID
  EXPORT_SAMPLING_MATERIAL: 'export_sampling_material', // 导出抽检审核记录
  MESSAGE_CENTER_REPORT_VIEW: 'message_center_report_view', // 消息中心报表查看
  GROSS_LOCK: 'gross_lock', //毛利锁定
  WAREHOUSE_MATCHING_RULE_CONFIG_PERMISSIONS: 'warehouse_matching_rule_config_permissions', // 仓库匹配规则配置
  MULTI_WAREHOUSE_ORDER_ALLOCATION_RULE: 'multi_warehouse_order_allocation_rule', //多仓订单分配规则配置
  DEAL_FB_ASSIGN_AUTHORIZED: 'deal_fb_assign_authorized', //编辑资产
  SAVE_FACEBOOK_PAGE: 'save_facebook_page', //编辑page列表
  DELETE_FACEBOOK_PAGE: 'delete_facebook_page', //删除page列表
  GET_SALES_BY_DEPARTMENT: 'get_sales_by_department', // 获取按部门销量
  GET_SHOPNOVA_STORE: 'get_shopnova_store', // shopnova店铺相关
  GET_FUNVALUE_STORE: 'get_funvalue_store', // funvalue店铺相关
  PROFIT_CONFIG_SUMMARY: 'profit_config_summary', // 查看毛利核算特殊部门汇总数据
  AD_SPEND_EXCEPTION_APPEAL: 'ad_spend_exception_appeal', //广告花费异常申诉
  AD_SPEND_EXCEPTION_HANDLING: 'ad_spend_exception_handling', //广告花费异常处理
  WRITE_ENTITY_UNASSIGNED_ACCOUNTS: 'write_entity_unassigned_accounts', //主体未分配账号列表编辑
  DEDUCT_SKU_SPEND: 'deduct_sku_spend', //剔除单扣减广告花费
  AD_ACCOUNT_ABNORMAL_EXPORT: 'ad_account_abnormal_export', // 导出异常广告账号
  WORK_ORDER_HANDLER_WITHDRAWS_MESSAGE: 'work_order_handler_withdraws_message', //工单处理人撤回
  VIEW_SKU_OWNER: 'view_sku_owner', //查看SKU归属人权限
  VIEW_TIKTOK_REVIEW: 'view_tiktok_review', //查看tiktok审核权限
  REVIEW_TIKTOK_REVIEW: 'review_tiktok_review', //审核tiktok素材权限
  CREATE_TIKTOK_REVIEW: 'create_tiktok_review', //提交tiktok审核权限
  SHIPPING_POLICY: 'shipping_policy', //运费策略
  WRITE_TIK_TOK_PERSONAL_ACCOUNTS: 'write_tik_tok_personal_accounts', //TT个人号列表查看
  TRANSFER_WORK_ORDER_TYPE: 'transfer_work_order_type', //工单转接
  OPTIMIZE_CONFIG_IMPORT: 'optimize_config_import', //优化配置导入
  SHOW_DOMAIN_TOTAL_SPEND: 'show_domain_total_spend', // 域名汇总金额
  EDIT_DOMAIN_IS_CREDIT_CARD: 'edit_domain_is_credit_card', // 是否信用卡域名编辑
  EDIT_DOMAIN_USAGE: 'edit_domain_usage', // 域名使用类型编辑
  PRODUCT_FREIGHT_TRIAL: 'product_freight_trial', // 产品运费试算
  WEIGHT_FREIGHT_TRIAL: 'weight_freight_trial', // 重量运费试算
  FREIGHT_DETAIL: 'freight_detail', // 运费详情
  VIEW_AD_ACCOUNT_REGISTER_INFO: 'view_ad_account_register_info', // 查看广告账号开户登记
  GET_REVIEWER_ANALYZE: 'get_reviewer_analyze', //查看审核人员分析数据
  UPDATE_USER_MA_BANG_NAME: 'update_user_ma_bang_name', // 马帮名称简写编辑权限
  SHOW_USER_MA_BANG_NAME: 'show_user_ma_bang_name', //马帮名称简写查看权限
  SHOW_THIRD_DOMAIN_DNS: 'show_third_domain_dns', // 域名解析查看
  STORE_MNG_UPDATE_PP_ACDC: 'store_mng_update_pp_acdc', // 店铺管理-修改ACDC状态
  EDIT_SALE_FACE_MASK: 'edit_sale_face_mask', //是否可投放口罩编辑
  PRODUCT_ROI_TRIAL: 'product_roi_trial', // 产品Roi试算
  COMMODITY_FREIGHT_TRIAL: 'commodity_freight_trial', // 商品运费试算
  UPDATE_USER_BASE_INFO: 'update_user_base_info', //用户管理个人信息更新
  EXPORT_LARR_STATISTICS: 'export_larr_statistics', // 导出LARR数据报表
  SHOW_SKU_DAILY_REVIEW_STATISTICS: 'show_sku_daily_review_statistics',
  VS_SKU_INFRINGEMENT_REVIEW_LIST: 'vs_sku_infringement_review_list', //查看违规审核失败处理列表
  DEAL_VS_SKU_INFRINGEMENT_REVIEW: 'deal_vs_sku_infringement_review', //操作违规审核失败下架
  TAKE_DOWN_REVIEW_LIST: 'take_down_review_list', //查看BIP审核下架列表
  SPECIAL_OPERATIONS_EDIT: 'special_operations_edit', // 特殊运营编辑
  ORDER_SKU_CALC: 'order_sku_calc', // 订单sku核算
  ORDER_FREIGHT_DETAIL_EXPORT: 'order_freight_detail_export', // 订单明细导出
  SKU_CALC: 'sku_calc', // sku核算
  SKU_SALES_ORDER_AND_SALES_SEARCH: 'sku_sales_order_and_sales_search', // SKU销量订单数及销量筛选项
  GET_MB_SKU_QUANTITY: 'get_mb_sku_quantity', // 马帮销量字段显示
  SHOW_RISK_PRODUCT_URL: 'show_risk_product_url', // 查看风控审核管理域名链接
  AFTER_SALE_ASSIGN_USER: 'after_sale_assign_user', //售后审核人员指派
  READ_TIK_TOK_MATERIAL_PUT_RECORD: 'read_tik_tok_material_put_record', //TT素材试投放记录查看
  WRITE_TIK_TOK_MATERIAL_PUT_RECORD: 'write_tik_tok_material_put_record', //TT素材试投放记录编辑
  IMPORT_TIK_TOK_MATERIAL_PUT_RECORD: 'import_tik_tok_material_put_record', //TT素材试投放记录导入
  COMMISSIONER_PERMISSIONS: 'commissioner_permissions', //专员权限
  DIRECTOR_PERMISSIONS: 'director_permissions', //主管权限
  VIEW_PERSONNEL_PERMISSIONS: 'view_personnel_permissions', //查看人员权限
  EXPORT_BOM_SUPPLY_CHAIN_LIST: 'export_bom_supply_chain_list', //导出供应链BOM表记录
  TEXT_IMAGE_CUSTOM_QUERY_TASK: 'text_image_custom_query_task', //文本图像定制新增查询任务
  ASSIGN_RISK_ASSIGNER: 'assign_risk_assigner', // 风控审核人员指派
  SHOW_RISK_BM_ID: 'show_risk_bm_id', // 查看风控审核bm_id
  WRITE_TIK_TOK_ADS_USER_LOG: 'write_tik_tok_ads_user_log', //TT广告户人员归属时间更新
  VIEW_THE_DETAILS_OF_THE_ORDER_CHANNEL: 'view_the_details_of_the_order_channel', //查看出单渠道详情
  AFTER_SALE_REVIEW_STATISTICS: 'after_sale_review_statistics', //审核绩效看板-产品售后审核
  AFTER_SALE_REVIEW_DISPUTE_STATISTICS: 'after_sale_review_dispute_statistics', //审核绩效看板-投诉率审核
  SKU_FREIGHT_CONFIG: 'sku_freight_config', //SKU运费配置
  SKU_ATTRIBUTE_CONFIG: 'sku_attribute_config', // SKU指定属性配置
  EDIT_SKU_ATTRIBUTE_CONFIG: 'edit_sku_attribute_config', // SKU指定属性编辑
  DEAL_SKU_FREIGHT_CONFIG: 'deal_sku_freight_config', //SKU运费配置编辑
  VIEW_AD_SPEND_USD_FILED: 'view_ad_spend_usd_filed', // 毛利核算-是否展示核算广告花费（$）
  OPERATOR_AD_SPEND_AND_SALE_CNY: 'operator_ad_spend_and_sale_cny', // 毛利核算-真实营业额与核算广告花费筛选
  GET_NORMAL_MATERIALS: 'get_normal_materials', // 合规素材库查看
  ADD_NORMAL_MATERIALS: 'add_normal_materials', // 合规素材库上传
  UPDATE_NORMAL_MATERIALS: 'update_normal_materials', // 合规素材库编辑
  EXPORT_NORMAL_MATERIALS: 'export_normal_materials', // 合规素材库导出
  SINGLE_ORDER_SALES_ANALYZE_SENSITIVE_INFORMATION:
    'single_order_sales_analyze_sensitive_information', // 单品订单统计-敏感信息
  SINGLE_ORDER_SALES_ANALYZE_SENSITIVE_INFO_CONFIG:
    'single_order_sales_analyze_sensitive_info_config', // 单品订单统计-敏感信息配置
  BATCH_DEP_OR_REST_OF_DOMAIN: 'batch_dep_or_rest_of_domain', //域名批量弃用/恢复
  RISK_PERFORMANCE_STATISTICS: 'risk_performance_statistics', // 审核绩效看板-风控审核
  OLDSTORE_TRANS_AUTHORIZE_SUBMIT: 'oldstore_trans_authorize_submit', // 老店铺转移/店铺授权工单提交
  APPLY_NEW_STORE_SUBMIT: 'apply_new_store_submit', //新店铺申请工单提交
  DOMAIN_BIND_SUBMIT: 'domain_bind_submit', // 域名绑定工单提交
  DATA_ERROR_MSG_EVALUATE: 'data_error_msg_evaluate', // 工单-评价内容
  CREDIT_CARD_AUDIT_SUBMIT: 'credit_card_audit_submit', //信用卡审核工单提交
  GET_SKU_CALC_DATA: 'get_sku_calc_data',
  REFRESH_ORDER_DATA: 'refresh_order_data', // 手动刷新订单数据
  ERP_ORDER_COUNTRY_LOCK: 'erp_order_country_lock', // SKU核算-订单国家同步ERP锁定
  ADD_INFRINGE_CASE: 'add_infringe_case', // 案例管理-新增
  UPDATE_INFRINGE_CASE: 'update_infringe_case', // 案例管理-编辑
  DEL_INFRINGE_CASE: 'del_infringe_case', // 侵权案例库删除
  WRITE_CREDIT_CARD_APPLICATION_LIST: 'write_credit_card_application_list', //信用卡申请列表编辑权限
  IMPORT_CREDIT_CARD_APPLICATION_LIST: 'import_credit_card_application_list', //信用卡申请列表导入权限
  EXPORT_CREDIT_CARD_APPLICATION_LIST: 'export_credit_card_application_list', //信用卡申请列表导出权限
  CREATE_TT_ASSETS: 'create_tt_assets', //创建成套资产权限
  ASSIGN_TT_ASSETS: 'assign_tt_assets', //资产分配/转移权限
  EDIT_TT_ASSETS: 'edit_tt_assets', //成套资产编辑权限
  TRANSFER_TT_ADVERTISER_ASSETS: 'transfer_tt_advertiser_assets', //广告户转移
  TT_ADVERTISER_ASSIGN_RETRY: 'tt_advertiser_assign_retry', //成套资产重新授权
  OPERATION_REMARK: 'operation_remark', // 资产审核-运营备注
  OPERATORS_HEALTH_CONFIG: 'operators_health_config', // 运营健康度分析-健康度设置
  GET_INFRINGE_DETAIL: 'get_infringe_detail', // 侵权展示库运营视觉权限
  INFRINGE_SHOW_AUDIT_FIELD: 'infringe_show_audit_field', // 侵权展示库审核人员视觉权限
  INFRINGE_SHOW_MANAGE_FIELD: 'infringe_show_manage_field', // 侵权展示库管理组视觉权限
  TT_ISSUES_SUBMIT: 'tt_issues_submit', //TT问题工单提交
  STORE_SHARING_SUBMIT: 'store_sharing_submit', // 店铺共享工单提交
  VIEW_RISK_ADS_STRUCTURE: 'view_risk_ads_structure', // 广告贴投放详情展示
  KLARNA_PAY_APPLY_SUBMIT: 'klarna_pay_apply_submit', // Klarna本地支付申请工单提交
  DEAL_PRODUCT_PROPERTIES_PURCHASE: 'deal_product_properties_purchase', // 产品属性编辑（采购）
  EDIT_CUSTOMIZE_EMAIL_ABBREVIATE: 'edit_customize_email_abbreviate', //店铺管理-PP简称编辑
  EDIT_CUSTOMIZE_CUSTOMER_SERVICE_EMAIL: 'edit_customize_customer_service_email', //店铺管理-店铺客服邮箱编辑
  EDIT_CUSTOMIZE_ASSIGNED_DATE: 'edit_customize_assigned_date', //店铺管理-编辑实际分配时间
  VIEW_CUSTOMIZE_CUSTOMER_SERVICE_EMAIL: 'view_customize_customer_service_email', //店铺管理-显示店铺客服邮箱
  IMPORT_MONITOR_POST_ID: 'import_monitor_post_id', // 素材审核-广告贴监测-导入监测帖子
  VIEW_MONITOR_POST_ID: 'view_monitor_post_id', // 素材审核-广告贴监测-查看监测帖子
  CLOSE_MONITOR_POST_ID: 'close_monitor_post_id', // 素材审核-广告贴监测-下架帖子
  GET_FOLLOW_PEOPLE: 'get_follow_people', //跟进人员显示
  DEAL_PEOPLE_SUBMIT_CUSTOMIZED: 'deal_people_submit_customized', //产品审核-编辑关联人员提交的定制产品
  UPDATE_FOLLOW_PEOPLE: 'update_follow_people', //跟进人员编辑,
  VIEW_TAKE_DOWN_TASK: 'view_take_down_task', //查看紧急下架,
  CREATE_TAKE_DOWN_TASK: 'create_take_down_task', //创建紧急下架任务,
  TO_TAKE_DOWN_PRODUCT_URL: 'to_take_down_product_url', //下架落地页,
  SHOW_MACHINE_REVIEW: 'show_machine_review', //显示机器审核结果
  READ_APPEAL_AD_REVIEWS: 'read_appeal_ad_reviews', // 素材审核-申诉处理标记查看
  WRITE_APPEAL_AD_REVIEWS: 'write_appeal_ad_reviews', // 素材审核-申诉处理标记编辑
  OPTIMAL_CHANNEL_ONLINE_CALCULATION: 'optimal_channel_online_calculation', //最优渠道在线计算
  LOGISTIC_CHANNEL_CONFIGS: 'logistic_channel_configs', //物流渠道管理
  AFTER_SALE_VIEW_ASSIGNER: 'after_sale_view_assigner', //售后审核-查看审核人
  AFTER_SALE_VIEW_IS_CHANGE_AFTER_PASSED: 'after_sale_view_is_change_after_passed', //售后审核-查看审核后是否改动
  AFTER_SALE_VIEW_PRODUCT_VARIANTS: 'after_sale_view_product_variants', //售后审核-查看店铺商品设置
  AFTER_SALE_VIEW_YESTERDAY_HAS_ORDER: 'after_sale_view_yesterday_has_order', //售后审核-查看是否投放
  AFTER_SALE_EXPORT_CHECK_LIST: 'after_sale_export_check_list', //售后审核-导出审核列表
  AFTER_SALE_EXPORT_CHECK_LOGS: 'after_sale_export_check_logs', //售后审核-导出审核记录
  CHECKED_BY_OLD_AFTER_SALE: 'checked_by_old_after_sale', //  售后审核-产品售后审核是否已处理
  VIEW_ORDER_COUNT_DISPUTE_RATE_TIP: 'view_order_count_dispute_rate_tip', // 显示订单数与投诉率提示语
  USPS_DELETE: 'usps_delete', //USPS物流信息删除
  FREIGHT_CALC_OPERATION: 'freight_calc_operation', // 运费试算操作
  VIEW_COORDINATION_REMARK: 'view_coordination_remark', //店铺管理-统筹部备注查看权限
  EDIT_COORDINATION_REMARK: 'edit_coordination_remark', //店铺管理-统筹部备注编辑权限
  USER_GROUP_NAME: 'user_group_name', //用户管理-所属小组编辑权限
  UPDATE_USER_GROUP_NAME: 'update_user_group_name', //用户管理-所属小组编辑权限
  USER_MANAGER_SHOW_USERNAME: 'user_manager_show_username', // 用户管理-姓名
  USER_MANAGER_SHOW_PERSONAL_EMAIL: 'user_manager_show_personal_email', // 用户管理-运营关联邮箱
  USER_MANAGER_BATCH_UPDATE_USER_PEOPLE: 'user_manager_batch_update_user_people', // 用户管理-批量关联用户
  SEARCH_REVIEW_FAILED_SKU: 'search_review_failed_sku', // 审核失败SKU查询
  SHOW_FULFILLMENT_CHANNELS_CALCULATOR_PRICE: 'show_fulfillment_channels_calculator_price', //最优渠道在线计算-价格展示
  READ_KEY_WORD: 'read_key_word', // 落地页风险管理-关键词展示
  WRITE_KEY_WORD: 'write_key_word', // 落地页风险管理-关键词编辑
  READ_KEY_WORD_PAGE: 'read_key_word_page', // 落地页风险管理-落地页展示
  WRITE_KEY_WORD_PAGE: 'write_key_word_page', // 落地页风险管理-落地页编辑
  WRITE_SCAN_KEY_WORD: 'write_scan_key_word', // 查看并编辑是否进行扫描
  FREIGHT_TRIAL_SPECIAL_SKU_CONFIG: 'freight_trial_special_sku_config', //运费试算特殊SKU配置
  ASSIGNED_PAGE: 'assigned_page', // page分配
  PRODUCT_REVIEW_SHOW_VIOLATING_PRODUCT: 'product_review_show_violating_product', // 产品审核违规情况显示纳入违规产品处理标识
  DEAL_ERP_COMPLETION: 'deal_erp_completion', // 编辑ERP完成情况字段
  DEAL_PRODUCT_REVIEW: 'deal_product_review', // 编辑商品审核字段
  DEAL_INFRACTION_TYPE: 'deal_infraction_type', // 编辑异常提醒字段
  DEAL_PROCESSING_METHOD: 'deal_processing_method', // 编辑处理方式字段
  DEAL_VIOLATING_INFRACTION: 'deal_violating_infraction', // 编辑违规信息字段
  SHOW_VIOLATING_PRODUCT: 'show_violating_product', // 查看违规产品处理页面
  USER_DOWNLOAD_TASK_SALESMONITORY: 'user_download_task_salesmonitory', //销量监测报表下载
  RISK_CONTROL_SYNC: 'risk_control_sync', // 风控审核同步操作
  SELECT_SALE_PAYMENT_GATEWAY: 'select_sale_payment_gateway', // 售后审核-收款渠道
  DEAL_SALE_REVIEW: 'deal_sale_review', // 售后审核-审核操作
  VIEW_SALE_DISPUTE_RATE: 'view_sale_dispute_rate', // 售后审核-投诉率查看
  REHEAR_SALE_REVIEW: 'rehear_sale_review', // 售后审核-提交复审
  VIEW_SALE_BIP_SUPPLIES: 'view_sale_bip_supplies', // 售后审核-查看BIP审核采购货源
  VIEW_SALE_PRODUCT_NOTE: 'view_sale_product_note', // 售后审核-产品备注查看
  EDIT_SALE_PRODUCT_NOTE: 'edit_sale_product_note', // 售后审核-产品备注编辑
  WRITE_FULFILLMENT_CHANNELS_CONFIGS: 'write_fulfillment_channels_configs', // 物流渠道数据
  WRITE_FULFILLMENT_CHANNELS_RATES: 'write_fulfillment_channels_rates', // 物流签收数据
  READ_FULFILLMENT_CHANNELS_PRICES: 'read_fulfillment_channels_prices', // 物流渠道测算
  READ_FULFILLMENT_CHANNELS_STATISTICS: 'read_fulfillment_channels_statistics', // 最优化渠道
  WRITE_FULFILLMENT_CHANNELS_SKU_BOOKS: 'write_fulfillment_channels_sku_books', // SKU抛货成本测算
  NORMAL_MATERIAL_APPLY: 'normal_material_apply', //新增申请合规素材
  GET_NORMAL_MATERIAL_APPLY: 'get_normal_material_apply', //查看申请合规素材
  REVIEWS_ADD_NORMAL_MATERIAL: 'reviews_add_normal_material', //加入合规素材库
  GET_MABANG_CATEGORY_SKU_SALES: 'get_mabang_category_sku_sales', //SKU马帮目录查看
  GET_VIOLATE_SKU_SALES: 'get_violate_sku_sales', // ERP）违规原因查看
  GET_ERP_CATEGORY_SKU_SALES: 'get_erp_category_sku_sales', // （ERP）商品分类查看
  VIEW_SKU_SALES_COST_URL: 'view_sku_sales_cost_url', // SKU销量-成本链接查看
  GET_CHECK_STAT_SKU_SALES: 'get_check_stat_sku_sales', //SKU审核状态查看
  VIOLATING_PRODUCT_IMAGE: 'violating_product_image', // 违规产品处理-编辑图片
  IMPORT_SKU_STOCK: 'import_sku_stock', //导入库存产品
  IMPORT_SKU_STOCK_LIST: 'import_sku_stock_list', // 库存产品导入任务列表
  STORE_LINK_CHECK: 'store_link_check', //实际出单店铺链接已审核勾选
  VIOLATE_CATEGORY: 'violate_category', //违规类型配置
  VIOLATE_CATEGORY_SUPPLY: 'violate_category_supply', //违规货源链接清单
  SUBMIT_COMPLIANT_PRODUCT: 'submit_compliant_product', // 提交合规库产品
  CORRECT_COMPLIANT_PRODUCT: 'correct_compliant_product', // 提交整改权限
  VIDEO_UPDATE_COMPLIANT_PRODUCT: 'video_update_compliant_product', // 视频素材编辑权限
  REMARK_UPDATE_COMPLIANT_PRODUCT: 'remark_update_compliant_product', // 审核填写备注权限
  FULFILLMENT_TIMELINESS_STATISTICS: 'fulfillment_timeliness_statistics', //物流时效统计
  FULFILLMENT_TIMELINESS_STATISTICS_USE_COUNTRY: 'fulfillment_timeliness_statistics_use_country', //常用国家上传
  FULFILLMENT_CHANNELS_SKU_COUNTRY_LIST: 'fulfillment_channels_sku_country_list', //sku抛货成本测算-国家列表
  SKU_POSTAL_COUNTRY_CONFIGS: 'sku_postal_country_configs', // 通邮国家配置
  SAME_STYLE_MERGER_STATUS: 'same_style_merger_status', // 编辑同款合并情况字段
  SELLING_PRICE_CALC: 'selling_price_calc', // 建议售价测算
  PREFERENTIAL_POLICY_CONFIG: 'preferential_policy_config', // 优惠政策配置
  FREIGHT_POLICY_CONFIG: 'freight_policy_config', // 运费政策配置
  WRITE_FULFILLMENT_CHANNELS_RECONCILIATIONS: 'write_fulfillment_channels_reconciliations', //物流对账渠道数据
  MENU_SEARCH: 'menu_search', //菜单搜索栏
  SHOW_ORDER_TOTAL_AMOUNT_USD: 'show_order_total_amount_usd', // 查看订单明细订单营业额(USD)字段
  UPDATE_COMPLIANT_PRODUCT: 'update_compliant_product', //合规库产品 编辑权限
  SELECT_SALE_CHANGE_SKU_NOT_ORDER: 'select_sale_change_sku_not_order', //售后审核-SKU变更未出单落地页筛选
  UPDATE_CHECK_COMPLIANT_PRODUCT: 'update_check_compliant_product', //合规库产品 编辑检查状态
  UPDATE_MATERIAL_COMPLIANT_PRODUCT: 'update_material_compliant_product', //合规库产品 编辑素材质量
  CALC_DETAILS: 'calc_details', //建议售价测算-测算详情
  DEBUG: 'debug',
  TIK_TOK_ADS_UPDATE_DOMAINS: 'tik_tok_ads_update_domains',
  BM_SHARE: 'bm_share', //资产共享
  READ_PAGES_ILLEGAL_PICTURE: 'read_pages_illegal_picture', //侵权图片展示
  WRITE_PAGES_ILLEGAL_PICTURE: 'write_pages_illegal_picture', //侵权图片编辑
  WRITE_CONFIG_DISTANCE: 'write_config_distance', // Distance设置
  PRODUCT_FLASH_EXPORT: 'product_flash_export', //快速上架
  FULFILLMENT_CATEGORY_RULE: 'fulfillment_category_rule', // 物流轨迹分类
  POST_MACHINE_REVIEW_INDEX: 'post_machine_review_index', //显示机器审核结果
  POST_MACHINE_REVIEW_DETAIL: 'post_machine_review_detail', //相同素材审核详情
  SKU_LOWER_SHIPPING_RATE_CONFIG: 'sku_lower_shipping_rate_config', //SKU发货情况 - 低发货率配置
  SKU_LOWER_SHIPPING_RATE_ORDER_INFO: 'sku_lower_shipping_rate_order_info', //SKU发货情况 - 导出低发货率订单详情
  WRITE_REVIEW_APPEAL_SETTING: 'write_review_appeal_setting', // 审核申诉-审核配置
  ESTIMATE_DISPUTE_CNT_NEW: 'estimate_dispute_cnt_new', // 预估最终投诉率试用
  ESTIMATE_DISPUTE_USD_NEW: 'estimate_dispute_usd_new', // 预估最终投诉金额率试用
  VIEW_RISK_ASSIGNER: 'view_risk_assigner', //显示分配审核人字段及查询项
  VIEW_RISK_SALE_INFRINGEMENT: 'view_risk_sale_infringement', //显示分配审核人字段及查询项
  VIEW_RISK_REVIEWER: 'view_risk_reviewer', //显示实际审核人字段
  VIEW_RISK_INFRINGEMENT_STANDARD: 'view_risk_infringement_standard', //显示侵权标准字段
  VIEW_RISK_FAIL_REASON: 'view_risk_fail_reason', //显示原因字段及查询项
  TT_ADVERTISER_RECYCLING: 'tt_advertiser_recycling', //广告户回收
  ORDER_EXPRESS_RATE_BY_STORE: 'order_express_rate_by_store', // 按店铺查看订单发货率
  WRITE_RESEARCH_PRODUCT_SELF: 'write_research_product_self', // 产品研发库-提交研发产品
  WRITE_RESEARCH_PRODUCT_ALL: 'write_research_product_all', // 产品研发库-编辑研发产品
  SKU_DISPUTE_ORDER_QUANTITY_RATE: 'sku_dispute_order_quantity_rate', //SKU争议-销量投诉率
  SKU_ESTIMATE_DISPUTE_ORDER_QUANTITY_RATE: 'sku_estimate_dispute_order_quantity_rate', //SKU争议-预估最终投诉销量率
  SKU_EXPRESS_RATE: 'sku_express_rate', //SKU争议-发货率
  SKU_DELIVERED_CNT_RATE: 'sku_delivered_cnt_rate', //SKU争议-发货妥投率
  SKU_DELIVERED_ORDER_RATE: 'sku_delivered_order_rate', //SKU争议-订单妥投率
  SKU_IS_MAIN: 'sku_is_main', //SKU争议-主sku数据
  NOT_RECEIVED_DESCRIBED_SALES: 'not_received_described_sales', // SKU争议-未收到货及描述不符销量数据
  ASSET_DISAPPROVED: 'asset_disapproved', //资产违规情况分析日报
  SUMMARY_STATISTICS_FIELD_SYNC_ERP_STATUS: 'summary_statistics_field_sync_erp_status', //汇总统计-查看修改ERP关联关系字段
  SKU_REVIEW_SUPPLY_COPY: 'sku_review_supply_copy', //产品审核-导出货源合并列
  POST_MACHINE_REVIEW_LATEST_INDEX: 'post_machine_review_latest_index', //显示机器审核结果(最新)
  POST_MACHINE_REVIEW_LATEST_DETAIL: 'post_machine_review_latest_detail', //显示机器审核详情(最新)
  VIEW_URL_DISPUTE_RATE: 'view_url_dispute_rate', // 售后审核-落地页投诉率查看
  VIOLATING_PRODUCT_WITHDRAWN: 'violating_product_withdrawn', // 违规产品处理已撤回记录查看
  SKU_CUSTOM_IMAGE_ZIP: 'sku_custom_image_zip', // 定制产品落地页图片导出
  DIFF_VALUE_TOP3_ATTR: 'diff_value_top3_attr', //运费统计对比-差异值top3属性
  ORDER_FAIL_CLASSIFICATION: 'order_fail_classification', // 每日马帮异常分类订单导出
  SKU_FREIGHT_CHANGE: 'sku_freight_change', //运费变动提醒
  SKU_PART_NOTICE_CONFIG: 'sku_part_notice_config', // 超期未审核SKU通知配置
  SKU_LIST_SHOW_ERP_CATEGORY: 'sku_list_show_erp_category', //ERP商品分类
  SKU_LIST_SHOW_ERP_VIOLATE: 'sku_list_show_erp_violate', // SKU列表查看是否违规
  SKU_DETAIL_MABANG: 'sku_detail_mabang', //SKU列表查看马帮目录
  EXCLUDE_GROSS_PROFIT: 'exclude_gross_profit', // 不计毛利tab
  DEAL_EXCLUDE_GROSS_PROFIT: 'deal_exclude_gross_profit', // 导入不计毛利SKU
  EXCLUDE_GROSS_PROFIT_EXPORT: 'exclude_gross_profit_export', // 导出不计毛利SKU
  USER_MANAGER_PASSWORD_EFFECTIVE_CONFIG: 'user_manager_password_effective_config', //密码策略
  REVIEW_ERROR_T_SKU: 'review_error_t_sku', // 审核失败关联真实SKU
  BATCH_RESET_PASSWORD: 'batch_reset_password', // 用户管理-批量重置密码
  BATCH_CREATE_USERS: 'batch_create_users', // 用户管理-导入新增用户
  LOGISTICS_PROVIDER: 'logistics_provider', //每周各物流商发货信息导出
  FULFILLMENT_PREVIEW_CHANNEL_DATA: 'fulfillment_preview_channel_data', //测试渠道数据
  VIEW_RISK_URL_SKU_REVIEW_FAILED: 'view_risk_url_sku_review_failed', //是否产品审核失败字段及筛选项
  VIEW_SALE_URL_SKU_REVIEW_FAILED: 'view_sale_url_sku_review_failed', //显示是否产品审核失败字段及筛选项
  VIEW_HIGH_RISK_RESOURCE: 'view_high_risk_resource', //查看下架监测页面
  STOP_HIGH_RISK_RESOURCE: 'stop_high_risk_resource', //下架监测页面下架/关停操作权限
  LOGISTICS_FREIGHT_LIST_EXPORT: 'logistics_freight_list_export', // 物流费用统计-列表导出
  USER_MANAGER_NAME_BATCH_SEARCH: 'user_manager_name_batch_search', // 用户管理-用户名称批量搜索
  ERROR_MSG_VIEW: 'error_msg_view', //工单提交 - 工单浏览标记
  EXPORT_OF_RELATED_PEOPLES: 'export_of_related_peoples', //用户管理-关联人员导出
  POST_RE_REVIEW: 'post_re_review', //素材审核-申请复审
  CUSTOM_UPDATE_PRODUCT_SUPPLY: 'custom_update_product_supply', // 产品审核 - 定制产品审核失败可编辑属性及供应商
  VIEW_SALE_RECTIFY_DELAYS: 'view_sale_rectify_delays', //整改滞留天数字段及筛选
  VIEW_SALE_URL_TAKE_DOWN_DELAYS: 'view_sale_url_take_down_delays', //下架滞留天数字段及筛选
  VIEW_SALE_URL_VIOLATES: 'view_sale_url_violates', //查看违规上架/ 投放字段及筛选
  SHOW_ERP_SKU_CATEGORY_TAGS: 'show_erp_sku_category_tags', // ERP自定义分类字段及筛选
  BIND_FOURTH_CREDIT_CARD: 'bind_fourth_credit_card', // 店铺管理-绑定信用卡通道4
  WRITE_FOURTH_CREDIT_CARD: 'write_fourth_credit_card', // 信用卡通道4的收款账号-操作
  READ_FOURTH_CREDIT_CARD: 'read_fourth_credit_card', // 信用卡通道4的收款账号-查看
  GET_DOMAIN_COUNTRY_SALES_INSENSITIVE: 'get_domain_country_sales_insensitive', //国家销量-脱敏数据查看
  GET_DOMAIN_COUNTRY_SALES: 'get_domain_country_sales', //国家销量-未脱敏数据查看
  WRITE_USER_COUNTRY: 'write_user_country', // 国家销量-国家权限配置
  VIEW_STORE_SIMPLE_PP_NAME: 'view_store_simple_pp_name', //店铺管理-店铺PP简称展示
  VIEW_STORE_AC_DC: 'view_store_ac_dc', //店铺管理-ACDC状态展示
  ACTION_WALLETS: 'action_wallets', // 店铺管理-电子钱包批量操作
  WRITE_WALLETS: 'write_wallets', // 电子钱包账号操作
  READ_WALLETS: 'read_wallets', // 电子钱包账号查看
  VIEW_PP_DISPUTE_AMOUNT: 'view_pp_dispute_amount', //pp账号争议-pp账号金额率
  SHOW_DEAL_SKU_COUNTRY: 'show_deal_sku_country', // 查看及编辑可投放国家
  WRITE_CREDIT_CARD_ENTITIES: 'write_credit_card_entities', //店铺管理-主体信息操作
  SKU_REVIEW_SALES_VIEW: 'sku_review_sales_view', // 查看累计销售数量字段
  SKU_REVIEW_ORDER_URL_VIEW: 'sku_review_order_url_view', // 查看SKU出单链接字段
  SINGLE_ORDER_SALES_ANALYZE_HISTORY_WEIGHT: 'single_order_sales_analyze_history_weight', //单品订单统计分析-历史重量
  VIEW_RISK_TAKE_DOWN_DELAYS: 'view_risk_take_down_delays', //风控下架滞留天数字段及筛选
  VIEW_RISK_RECTIFY_DELAYS: 'view_risk_rectify_delays', //风控整改滞留天数字段及筛选
  VIEW_RISK_URL_VIOLATES: 'view_risk_url_violates', //风控查看违规上架/投放字段及筛选
  VIEW_RISK_IS_LATEST_ONLY: 'view_risk_is_latest_only', //风控仅显示最新审核项
  BM_TOKEN_MANAGER_USER_APP_TOKEN: 'bm_token_manager_user_app_token', //BM TOKEN管理-个人号APP页面查看
  READ_FULFILLMENT_COST_DETAILS: 'read_fulfillment_cost_details', //物流重量差异查看
  READ_ESTIMATED_COST_IN_FULFILLMENT_COST_DETAILS:
    'read_estimated_cost_in_fulfillment_cost_details', //物流重量差异-预估运费展示
  READ_LOGISTICS_COST_IN_FULFILLMENT_COST_DETAILS:
    'read_logistics_cost_in_fulfillment_cost_details', //物流重量差异-物流运费展示
  WRITE_ESTIMATED_COST_IN_FULFILLMENT_COST_DETAILS:
    'write_estimated_cost_in_fulfillment_cost_details', //物流重量差异-更新预估运费
  WRITE_LOGISTICS_WEIGHT_IN_FULFILLMENT_COST_DETAILS:
    'write_logistics_weight_in_fulfillment_cost_details', //物流重量差异-上传物流重量
  BM_TOKEN_MANAGER_USER_TOKEN: 'bm_token_manager_user_token', //BM TOKEN管理-用户BM页面查看
  BM_TOKEN_MANAGER_OTHER_TOKEN: 'bm_token_manager_other_token', //BM TOKEN管理-其他BM页面查看
  STORE_MNG_LOCAL_PAYMENT_BATCH: 'store_mng_local_payment_batch', //店铺管理-本地支付批量操作
  KLARNA_PAY_CONFIG_VIEW: 'klarna_pay_config_view', //klarna支付账号管理-klarna支付账号查看
  KLARNA_PAY_CONFIG_OPERATE: 'klarna_pay_config_operate', //klarna支付账号管理-klarna支付账号操作
  ASSET_AD_ACCOUNT_RECYCLE_APPLY: 'asset_ad_account_recycle_apply', // 资产关系-广告账号回收申请
  SKU_DELETE_LIST_AND_DELETE_SON: 'sku_delete_list_and_delete_son', // 从SKU列表删除及支持删除继承子SKU
  V_SKU_NICE_TYPE_LIST: 'v_sku_nice_type_list', //SKU尼斯分类设置-虚拟SKU列表查看
  T_SKU_NICE_TYPE_LIST: 't_sku_nice_type_list', //SKU尼斯分类设置-真实SKU列表查看
  V_SKU_NICE_TYPE: 'v_sku_nice_type', //SKU尼斯分类设置-虚拟尼斯分类字段的查看权限
  T_SKU_NICE_TYPE: 't_sku_nice_type', //SKU尼斯分类设置-真实尼斯分类字段的查看权限
  EDIT_SKU_NICE_TYPE: 'edit_sku_nice_type', //SKU尼斯分类设置-设置尼斯分类
  READ_CALC_DATA_IN_FULFILLMENT_COST_DETAILS: 'read_calc_data_in_fulfillment_cost_details', //物流重量差异-物流计算数据展示
  EDIT_KEY_WORD_NICE_TYPE: 'edit_key_word_nice_type', // 关键词-尼斯分类操作
  POST_INFO_KEYWORD_SCAN: 'post_info_keyword_scan', //素材审核-关键词扫描
  VIOLATION_STORE_PUBLIC_URL: 'violation_store_public_url', // 更新违规信息-实际店铺出单链接字段显示
};
export default permissions;
