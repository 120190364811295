// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/preview",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__ProductPool__LinkPreview' */'/src/pages/SKU/ProductPool/LinkPreview'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "login",
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Login' */'/src/pages/User/Login'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/user-authorize",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user-authorize",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Authorize' */'/src/pages/User/Authorize'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/src/layouts/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/dashboard",
        "name": "Dashboard",
        "icon": "smile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DashBoard' */'/src/pages/DashBoard'), loading: LoadingComponent}),
        "authority": [
          "dashboard"
        ],
        "access": "permissionFilter",
        "exact": true
      },
      {
        "path": "/order_analysis",
        "name": "订单分析",
        "icon": "container",
        "routes": [
          {
            "path": "/order_analysis/total",
            "name": "订单汇总",
            "icon": "unordered-list",
            "authority": [
              "get_stores_orders"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Specified' */'/src/pages/Order/Specified'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/order_analysis/order_details",
            "name": "订单详情",
            "icon": "unordered-list",
            "authority": [
              "order_details"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Detail' */'/src/pages/Order/Detail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/order_analysis/platform",
            "name": "多维分析",
            "icon": "unordered-list",
            "authority": [
              "platform_analysis",
              "order_compare_analysis",
              "order_group_payment_analysis",
              "ws_abandoned_list"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Platform' */'/src/pages/Order/Platform'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/order_analysis/refund_detail",
            "name": "退款明细",
            "icon": "unordered-list",
            "authority": [
              "order_refund_detail"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__RefundDetail' */'/src/pages/Order/RefundDetail'), loading: LoadingComponent}),
            "exact": true
          }
        ],
        "authority": [
          "get_stores_orders",
          "order_details",
          "platform_analysis",
          "order_compare_analysis",
          "order_group_payment_analysis",
          "ws_abandoned_list",
          "order_refund_detail"
        ]
      },
      {
        "path": "/dispute",
        "name": "争议分析",
        "icon": "bar-chart",
        "routes": [
          {
            "path": "/dispute/store",
            "name": "店铺争议",
            "icon": "bar-chart",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispute__Store' */'/src/pages/Dispute/Store'), loading: LoadingComponent}),
            "authority": [
              "only_domain_dispute_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/dispute/sku",
            "name": "SKU争议",
            "icon": "bar-chart",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispute__SKU' */'/src/pages/Dispute/SKU'), loading: LoadingComponent}),
            "authority": [
              "sku_dispute_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/dispute/ppAccount",
            "name": "PP账号争议",
            "icon": "bar-chart",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispute__PPAccount' */'/src/pages/Dispute/PPAccount'), loading: LoadingComponent}),
            "authority": [
              "pp_dispute_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/dispute/operation",
            "name": "团队争议",
            "icon": "bar-chart",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispute__Operation' */'/src/pages/Dispute/Operation'), loading: LoadingComponent}),
            "authority": [
              "store_dispute_list"
            ],
            "access": "permissionFilter",
            "exact": true
          }
        ],
        "authority": [
          "only_domain_dispute_list",
          "sku_dispute_list",
          "pp_dispute_list",
          "store_dispute_list"
        ]
      },
      {
        "path": "/track_management",
        "name": "物流管理",
        "icon": "control",
        "routes": [
          {
            "path": "/track_management/query",
            "name": "物流查询",
            "icon": "search",
            "authority": [
              "logistics_query"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__Query' */'/src/pages/Track/Query'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/track_management/logistics_tracking",
            "name": "物流跟踪",
            "icon": "search",
            "authority": [
              "logistics_ticket"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__LogisticsTracking' */'/src/pages/Track/LogisticsTracking'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/track_management/search",
            "name": "虚拟单号管理",
            "icon": "search",
            "authority": [
              "virtual_real_track_search"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__Search' */'/src/pages/Track/Search'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/track_management/order_logistics",
            "name": "订单物流处理",
            "icon": "search",
            "authority": [
              "order_logistics_processing",
              "order_address_verification"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__OrderLogistics' */'/src/pages/Track/OrderLogistics'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/track_management/trackhub",
            "name": "trackhub查询",
            "icon": "search",
            "authority": [
              "tracking_hub_search"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__TrackingHub' */'/src/pages/Track/TrackingHub'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/track_management/tracking",
            "name": "运单号管理",
            "icon": "bars",
            "authority": [
              "update_fulfillments"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__Tracking' */'/src/pages/Track/Tracking'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/track_management/logistics_Channel",
            "name": "物流渠道管理",
            "icon": "bars",
            "authority": [
              "write_fulfillment_channels_configs",
              "write_fulfillment_channels_rates",
              "read_fulfillment_channels_prices",
              "read_fulfillment_channels_statistics",
              "write_fulfillment_channels_sku_books",
              "optimal_channel_online_calculation"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__LogisticsChannel' */'/src/pages/Track/LogisticsChannel'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/track_management/logistics_weight_difference",
            "name": "物流重量差异",
            "icon": "bars",
            "authority": [
              "read_fulfillment_cost_details"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__LogisticsWeightDifference' */'/src/pages/Track/LogisticsWeightDifference'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/track_management/usps_search",
            "name": "USPS查询",
            "icon": "bars",
            "authority": [
              "usps_index"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__USPSSearch' */'/src/pages/Track/USPSSearch'), loading: LoadingComponent}),
            "exact": true
          }
        ],
        "authority": [
          "logistics_query",
          "logistics_ticket",
          "virtual_real_track_search",
          "order_logistics_processing",
          "order_address_verification",
          "tracking_hub_search",
          "update_fulfillments",
          "write_fulfillment_channels_configs",
          "write_fulfillment_channels_rates",
          "read_fulfillment_channels_prices",
          "read_fulfillment_channels_statistics",
          "write_fulfillment_channels_sku_books",
          "optimal_channel_online_calculation",
          "read_fulfillment_cost_details",
          "usps_index"
        ]
      },
      {
        "path": "/ad",
        "name": "广告分析",
        "icon": "fund",
        "routes": [
          {
            "path": "/ad/spend",
            "name": "FB账户花费",
            "icon": "pay-circle",
            "authority": [
              "account_spend"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Advertisement__Spend' */'/src/pages/Advertisement/Spend'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/ad/tt-account-expenses",
            "name": "TT账户花费",
            "icon": "pay-circle",
            "authority": [
              "ad_tt_ad_spend"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Advertisement__TtAccountExpenses' */'/src/pages/Advertisement/TtAccountExpenses'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/ad/google-ad-spend",
            "name": "谷歌账户花费",
            "icon": "pay-circle",
            "authority": [
              "google_account_spend"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Advertisement__GoogleAdSpend' */'/src/pages/Advertisement/GoogleAdSpend'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/ad/cpm",
            "name": "指标分析",
            "icon": "bar-chart",
            "authority": [
              "cpm_analysis"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Advertisement__CpmAnalysis' */'/src/pages/Advertisement/CpmAnalysis'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/ad/real-time-spending",
            "name": "实时花费",
            "icon": "bar-chart",
            "authority": [
              "real_time_ad_spend"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Advertisement__RealTimeSpending' */'/src/pages/Advertisement/RealTimeSpending'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/ad/entity-spend",
            "name": "PC-广告查询",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Advertisement__EntitySpend' */'/src/pages/Advertisement/EntitySpend'), loading: LoadingComponent}),
            "authority": [
              "ad_entity_spend"
            ],
            "access": "permissionFilter",
            "exact": true
          }
        ],
        "authority": [
          "account_spend",
          "ad_tt_ad_spend",
          "google_account_spend",
          "cpm_analysis",
          "real_time_ad_spend",
          "ad_entity_spend"
        ]
      },
      {
        "path": "/sku/tSku-customized",
        "name": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__TSkuCustomized' */'/src/pages/SKU/TSkuCustomized'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/sku/customed-product",
        "name": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__CustomizedProduct' */'/src/pages/SKU/CustomizedProduct'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/account/property_assessment/detail",
        "name": "",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__PropertyAssessment__components__Detail' */'/src/pages/Account/PropertyAssessment/components/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/sku",
        "name": "SKU管理",
        "icon": "gold",
        "routes": [
          {
            "path": "/sku/review",
            "name": "产品审核",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__Review' */'/src/pages/SKU/Review'), loading: LoadingComponent}),
            "authority": [
              "get_sku_review"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/illegal_product_processing",
            "name": "违规产品处理",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__IllegalProductProcessing' */'/src/pages/SKU/IllegalProductProcessing'), loading: LoadingComponent}),
            "authority": [
              "show_violating_product",
              "deal_erp_completion",
              "deal_product_review",
              "deal_processing_method",
              "deal_infraction_type",
              "deal_violating_infraction"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/product_trial",
            "name": "产品运费试算",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__ProductTrial' */'/src/pages/SKU/ProductTrial'), loading: LoadingComponent}),
            "authority": [
              "sku_freight_change",
              "product_freight_trial",
              "weight_freight_trial",
              "product_roi_trial",
              "commodity_freight_trial",
              "selling_price_calc"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/replacesupply",
            "name": "替换货源",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__ReplaceSupply' */'/src/pages/SKU/ReplaceSupply'), loading: LoadingComponent}),
            "authority": [
              "sku_replace_supply_index"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/hot",
            "name": "SKU热销榜",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__Hot' */'/src/pages/SKU/Hot'), loading: LoadingComponent}),
            "authority": [
              "sku_hot_sale_pass_page",
              "sku_hot_sale_all_page"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/unShelveOverSevenDays",
            "name": "7天未下采购单SKU",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__UnShelveOverSevenDays' */'/src/pages/SKU/UnShelveOverSevenDays'), loading: LoadingComponent}),
            "authority": [
              "user_show_seven_days_not_placed_purchase_sku",
              "admin_show_seven_days_not_placed_purchase_sku"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/produrtPool",
            "name": "测试产品池",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__ProductPool' */'/src/pages/SKU/ProductPool'), loading: LoadingComponent}),
            "authority": [
              "porduct_test_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/customized",
            "name": "定制产品管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__CustomizedProductManage' */'/src/pages/SKU/CustomizedProductManage'), loading: LoadingComponent}),
            "authority": [
              "customized_product_management"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/list",
            "name": "SKU列表",
            "icon": "unordered-list",
            "authority": [
              "get_history_sku"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__List' */'/src/pages/SKU/List'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/complexSku",
            "name": "组合SKU",
            "icon": "unordered-list",
            "authority": [
              "get_sku_review"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__ComplexSku' */'/src/pages/SKU/ComplexSku'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/procurement_supply_chain",
            "name": "SKU供应链信息",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__ProcurementSupplyChain' */'/src/pages/SKU/ProcurementSupplyChain'), loading: LoadingComponent}),
            "authority": [
              "purchase_supply_chain_update",
              "purchase_supply_chain_ts_info",
              "purchase_supply_chain_vs_info"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/out_of_sku_info",
            "name": "SKU缺货信息",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__SkuOutOfStock' */'/src/pages/SKU/SkuOutOfStock'), loading: LoadingComponent}),
            "authority": [
              "purchase_supply_chain_storage_info"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/statistics",
            "name": "汇总统计",
            "icon": "unordered-list",
            "authority": [
              "sku_category_product_statistics",
              "sku_product_statistics",
              "delete_sku_detail",
              "view_sku_void_order_summary",
              "show_sku_daily_review_statistics"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__Statistics' */'/src/pages/SKU/Statistics'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/on_offer",
            "name": "在售SKU",
            "icon": "unordered-list",
            "authority": [
              "on_offer_sku_manage"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__OnOffer' */'/src/pages/SKU/OnOffer'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/un_shelve",
            "name": "SKU下架日志",
            "icon": "unordered-list",
            "authority": [
              "un_shelve_sku"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__UnShelve' */'/src/pages/SKU/UnShelve'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/stock",
            "name": "库存SKU导入",
            "icon": "unordered-list",
            "authority": [
              "stock_sku_search"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__Stock' */'/src/pages/SKU/Stock'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/manage",
            "name": "库存SKU管理",
            "icon": "unordered-list",
            "authority": [
              "stock_sku_manage"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__Manage' */'/src/pages/SKU/Manage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/infringement",
            "name": "侵权商品",
            "icon": "unordered-list",
            "authority": [
              "get_sku_infringement_list"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__Infringement' */'/src/pages/SKU/Infringement'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/recommend",
            "name": "供应商推品",
            "icon": "unordered-list",
            "authority": [
              "get_recommend_product_list"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__Recommend' */'/src/pages/SKU/Recommend'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/stock-product",
            "name": "库存产品",
            "icon": "unordered-list",
            "authority": [
              "get_stock_sku"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__StockProduct' */'/src/pages/SKU/StockProduct'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/circumstance",
            "name": "SKU库存情况",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__Circumstance' */'/src/pages/SKU/Circumstance'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "authority": [
              "sku_stock_situation"
            ],
            "exact": true
          },
          {
            "path": "/sku/custom-classification",
            "name": "自定义分类",
            "icon": "unordered-list",
            "authority": [
              "customize_cate_not_create_del"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__CustomClassification' */'/src/pages/SKU/CustomClassification'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sku/stockup",
            "name": "备货计划",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__StockUp' */'/src/pages/SKU/StockUp'), loading: LoadingComponent}),
            "authority": [
              "stock_up_application_index"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/vendorList",
            "name": "供应商列表",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__VendorList' */'/src/pages/SKU/VendorList'), loading: LoadingComponent}),
            "authority": [
              "sku_supplier_page"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/logistics_country_configuration",
            "name": "物流国家配置",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__LogisticsCountryConfiguration' */'/src/pages/SKU/LogisticsCountryConfiguration'), loading: LoadingComponent}),
            "authority": [
              "read_sku_postal_country_configs",
              "write_sku_postal_country_configs"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/price-exceptions",
            "name": "SKU异常统一成本价",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__PriceExceptions' */'/src/pages/SKU/PriceExceptions'), loading: LoadingComponent}),
            "authority": [
              "abnormal_cost_sku"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/stop-work-Message",
            "name": "SKU停复工信息",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__StopWorkMessage' */'/src/pages/SKU/StopWorkMessage'), loading: LoadingComponent}),
            "authority": [
              "view_stop_sku_record",
              "edit_stop_sku_record"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/sku/supply-chain-bom",
            "name": "供应链BOM表",
            "icon": "carry-out",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SKU__SupplyChainBom' */'/src/pages/SKU/SupplyChainBom'), loading: LoadingComponent}),
            "authority": [
              "view_bom_supply_chain_list"
            ],
            "access": "permissionFilter",
            "exact": true
          }
        ],
        "authority": [
          "get_sku_review",
          "show_violating_product",
          "deal_erp_completion",
          "deal_product_review",
          "deal_processing_method",
          "deal_infraction_type",
          "deal_violating_infraction",
          "sku_freight_change",
          "product_freight_trial",
          "weight_freight_trial",
          "product_roi_trial",
          "commodity_freight_trial",
          "selling_price_calc",
          "sku_replace_supply_index",
          "sku_hot_sale_pass_page",
          "sku_hot_sale_all_page",
          "user_show_seven_days_not_placed_purchase_sku",
          "admin_show_seven_days_not_placed_purchase_sku",
          "porduct_test_list",
          "customized_product_management",
          "get_history_sku",
          "get_sku_review",
          "purchase_supply_chain_update",
          "purchase_supply_chain_ts_info",
          "purchase_supply_chain_vs_info",
          "purchase_supply_chain_storage_info",
          "sku_category_product_statistics",
          "sku_product_statistics",
          "delete_sku_detail",
          "view_sku_void_order_summary",
          "show_sku_daily_review_statistics",
          "on_offer_sku_manage",
          "un_shelve_sku",
          "stock_sku_search",
          "stock_sku_manage",
          "get_sku_infringement_list",
          "get_recommend_product_list",
          "get_stock_sku",
          "sku_stock_situation",
          "customize_cate_not_create_del",
          "stock_up_application_index",
          "sku_supplier_page",
          "read_sku_postal_country_configs",
          "write_sku_postal_country_configs",
          "abnormal_cost_sku",
          "view_stop_sku_record",
          "edit_stop_sku_record",
          "view_bom_supply_chain_list"
        ]
      },
      {
        "path": "/product",
        "name": "商品管理",
        "icon": "shop",
        "routes": [
          {
            "path": "/product/import",
            "name": "产品导入",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Import' */'/src/pages/Product/Import'), loading: LoadingComponent}),
            "authority": [
              "product_import"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/product/logs",
            "name": "日志",
            "icon": "calendar",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Logs' */'/src/pages/Product/Logs'), loading: LoadingComponent}),
            "authority": [
              "product_import",
              "sku_review_batch_import"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/product/statistic",
            "name": "产品统计",
            "icon": "container",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Statistic' */'/src/pages/Product/Statistic'), loading: LoadingComponent}),
            "authority": [
              "import_export_statistics"
            ],
            "access": "permissionFilter",
            "exact": true
          }
        ],
        "authority": [
          "product_import",
          "product_import",
          "sku_review_batch_import",
          "import_export_statistics"
        ]
      },
      {
        "path": "/paypal",
        "name": "收款管理",
        "icon": "profile",
        "routes": [
          {
            "path": "/paypal/large_amount_logs",
            "name": "大额变动",
            "icon": "pay-circle",
            "authority": [
              "pay_large_changes_search"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__LargeAmountLogs' */'/src/pages/Paypal/LargeAmountLogs'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/params",
            "name": "PP参数",
            "icon": "pay-circle",
            "authority": [
              "pp_config_record_index",
              "pp_config_record_update"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__Params' */'/src/pages/Paypal/Params'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/receive",
            "name": "PP收款",
            "icon": "pay-circle",
            "authority": [
              "pp_amount"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__Receive' */'/src/pages/Paypal/Receive'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/balance",
            "name": "PP实时余额",
            "icon": "account-book",
            "authority": [
              "pp_actual_amount"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__Balance' */'/src/pages/Paypal/Balance'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/inquire",
            "name": "PP收款查询",
            "icon": "select",
            "authority": [
              "pp_amount"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__Inquire' */'/src/pages/Paypal/Inquire'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/list",
            "name": "PP账号管理",
            "icon": "unordered-list",
            "authority": [
              "show_pp_list"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__List' */'/src/pages/Paypal/List'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/XB",
            "name": "信用卡通道1账号管理",
            "icon": "edit",
            "authority": [
              "show_xb_list"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__XBAccount' */'/src/pages/Paypal/XBAccount'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/WP",
            "name": "信用卡通道2账号管理",
            "icon": "edit",
            "authority": [
              "show_wp_list"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__WPAccount' */'/src/pages/Paypal/WPAccount'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/credit-card-three",
            "name": "信用卡通道3账号管理",
            "icon": "edit",
            "authority": [
              "third_credit_card_manage"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__CreditCardThree' */'/src/pages/Paypal/CreditCardThree'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/credit-card-fourth",
            "name": "信用卡通道4账号管理",
            "icon": "edit",
            "authority": [
              "read_fourth_credit_card"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__CreditCardFourth' */'/src/pages/Paypal/CreditCardFourth'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/klarna-payment",
            "name": "klarna支付账号管理",
            "icon": "edit",
            "authority": [
              "klarna_pay_config_view"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__KlarnaPayment' */'/src/pages/Paypal/KlarnaPayment'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/wallet_account",
            "name": "电子钱包账号管理",
            "icon": "shop",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__WalletAccount' */'/src/pages/Paypal/WalletAccount'), loading: LoadingComponent}),
            "authority": [
              "read_wallets",
              "write_wallets"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/health",
            "name": "PP健康检查",
            "icon": "medicine-box",
            "authority": [
              "pp_health_examination"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__HealthExamination' */'/src/pages/Paypal/HealthExamination'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/Domain",
            "name": "域名收款",
            "icon": "edit",
            "authority": [
              "domain_order_transaction_list"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__Domain' */'/src/pages/Paypal/Domain'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/ppDomain",
            "name": "PP域名",
            "icon": "appstore",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__PPDomain' */'/src/pages/Paypal/PPDomain'), loading: LoadingComponent}),
            "authority": [
              "domain_pp_change_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/ppAlert",
            "name": "PP告警",
            "icon": "appstore",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__PPAlert' */'/src/pages/Paypal/PPAlert'), loading: LoadingComponent}),
            "authority": [
              "pp_alarm_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/paypal/credit-card-application-list",
            "name": "信用卡申请列表",
            "icon": "profile",
            "authority": [
              "read_credit_card_application_list"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Paypal__CreditCardApplication' */'/src/pages/Paypal/CreditCardApplication'), loading: LoadingComponent}),
            "exact": true
          }
        ],
        "authority": [
          "pay_large_changes_search",
          "pp_config_record_index",
          "pp_config_record_update",
          "pp_amount",
          "pp_actual_amount",
          "pp_amount",
          "show_pp_list",
          "show_xb_list",
          "show_wp_list",
          "third_credit_card_manage",
          "read_fourth_credit_card",
          "klarna_pay_config_view",
          "read_wallets",
          "write_wallets",
          "pp_health_examination",
          "domain_order_transaction_list",
          "domain_pp_change_list",
          "pp_alarm_list",
          "read_credit_card_application_list"
        ]
      },
      {
        "path": "/account",
        "name": "资产管理",
        "icon": "appstore",
        "routes": [
          {
            "path": "/account/ad-account-register",
            "name": "广告账号开户登记",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__AdAccountRegister' */'/src/pages/Account/AdAccountRegister'), loading: LoadingComponent}),
            "authority": [
              "view_ad_account_register_info"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/ad",
            "name": "广告户管理",
            "icon": "profile",
            "authority": [
              "get_ad_account"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__AdAccount' */'/src/pages/Account/AdAccount'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/account/tt-ad",
            "name": "TT广告户管理",
            "icon": "profile",
            "authority": [
              "read_tik_tok_ads"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__TtAdAccount' */'/src/pages/Account/TtAdAccount'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/account/property_assessment",
            "name": "资产盘点",
            "icon": "profile",
            "authority": [
              "get_inventory_works"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__PropertyAssessment' */'/src/pages/Account/PropertyAssessment'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/account/user",
            "name": "用户管理",
            "icon": "team",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__User' */'/src/pages/Account/User'), loading: LoadingComponent}),
            "authority": [
              "get_users_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/hermes-user",
            "name": "爱马仕-用户管理",
            "icon": "team",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__HermesUser' */'/src/pages/Account/HermesUser'), loading: LoadingComponent}),
            "authority": [
              "get_hermes_users_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/part",
            "name": "部门管理",
            "icon": "apartment",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Part' */'/src/pages/Account/Part'), loading: LoadingComponent}),
            "authority": [
              "get_parts"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/gooleAds",
            "name": "谷歌账户管理",
            "icon": "shop",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__GooleAds' */'/src/pages/Account/GooleAds'), loading: LoadingComponent}),
            "authority": [
              "google_ad_accounts_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/store",
            "name": "店铺管理",
            "icon": "shop",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Store' */'/src/pages/Account/Store'), loading: LoadingComponent}),
            "authority": [
              "get_stores"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/store/batchEdit",
            "name": "批量修改日志",
            "authority": [
              "excel_update_store_pp"
            ],
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Store__BatchEdit' */'/src/pages/Account/Store/BatchEdit'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/storeChanges",
            "name": "店铺变更",
            "icon": "shop",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__StoreChanges' */'/src/pages/Account/StoreChanges'), loading: LoadingComponent}),
            "authority": [
              "store_change_history"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/part_config",
            "name": "部门配置",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__PartConfig' */'/src/pages/Account/PartConfig'), loading: LoadingComponent}),
            "authority": [
              "get_part_configs"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/assets",
            "name": "资产关系",
            "icon": "wallet",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Assets' */'/src/pages/Account/Assets'), loading: LoadingComponent}),
            "authority": [
              "get_asset"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/assets_allocation",
            "name": "资产分配",
            "icon": "wallet",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__AssetsAllocation' */'/src/pages/Account/AssetsAllocation'), loading: LoadingComponent}),
            "authority": [
              "view_fb_assign_authorized"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/unallocated_accounts",
            "name": "主体未分配账号",
            "authority": [
              "read_entity_unassigned_accounts"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__UnallocatedAccounts' */'/src/pages/Account/UnallocatedAccounts'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/account/allocated_bm",
            "name": "账号已分配BM",
            "authority": [
              "view_account_shared_bm"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__AllocatedBM' */'/src/pages/Account/AllocatedBM'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/account/domainName",
            "name": "域名管理",
            "icon": "appstore",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__DomainNameManage' */'/src/pages/Account/DomainNameManage'), loading: LoadingComponent}),
            "authority": [
              "get_domain_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/page",
            "name": "Page管理",
            "icon": "FileTextOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__PageManager' */'/src/pages/Account/PageManager'), loading: LoadingComponent}),
            "authority": [
              "page_manage"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/page-list",
            "name": "Page列表",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__PageList' */'/src/pages/Account/PageList'), loading: LoadingComponent}),
            "authority": [
              "view_facebook_page"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/domain-store",
            "name": "域名日志",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__DomainStoreHistory' */'/src/pages/Account/DomainStoreHistory'), loading: LoadingComponent}),
            "authority": [
              "get_third_domain_log"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/credit-card",
            "name": "信用卡管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__CreditCard' */'/src/pages/Account/CreditCard'), loading: LoadingComponent}),
            "authority": [
              "get_credit_card_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/pixel",
            "name": "像素管理",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__Pixel' */'/src/pages/Account/Pixel'), loading: LoadingComponent}),
            "authority": [
              "get_pixel_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/bm-token",
            "name": "BM Token管理",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__BMToken' */'/src/pages/Account/BMToken'), loading: LoadingComponent}),
            "authority": [
              "bm_token_manager_user_token",
              "bm_token_manager_other_token",
              "bm_token_manager_user_app_token"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/tt-personal-number",
            "name": "TT个人号列表",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__TtPersonalList' */'/src/pages/Account/TtPersonalList'), loading: LoadingComponent}),
            "authority": [
              "read_tik_tok_personal_accounts"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/tt-asset-relation",
            "name": "TT资产关系",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__TtAssetRelation' */'/src/pages/Account/TtAssetRelation'), loading: LoadingComponent}),
            "authority": [
              "view_all_tt_data"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/advertise-name-sync",
            "name": "广告名称同步",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__AdvertiseNameSync' */'/src/pages/Account/AdvertiseNameSync'), loading: LoadingComponent}),
            "authority": [
              "ad_edit_online"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/account/ad-advertise-opening-statistics",
            "name": "广告户开户申请统计",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Account__AdAccountOpeningApplicationStatistics' */'/src/pages/Account/AdAccountOpeningApplicationStatistics'), loading: LoadingComponent}),
            "authority": [
              "asset_ad_account_apply"
            ],
            "access": "permissionFilter",
            "exact": true
          }
        ],
        "authority": [
          "view_ad_account_register_info",
          "get_ad_account",
          "read_tik_tok_ads",
          "get_inventory_works",
          "get_users_list",
          "get_hermes_users_list",
          "get_parts",
          "google_ad_accounts_list",
          "get_stores",
          "excel_update_store_pp",
          "store_change_history",
          "get_part_configs",
          "get_asset",
          "view_fb_assign_authorized",
          "read_entity_unassigned_accounts",
          "view_account_shared_bm",
          "get_domain_list",
          "page_manage",
          "view_facebook_page",
          "get_third_domain_log",
          "get_credit_card_list",
          "get_pixel_list",
          "bm_token_manager_user_token",
          "bm_token_manager_other_token",
          "bm_token_manager_user_app_token",
          "read_tik_tok_personal_accounts",
          "view_all_tt_data",
          "ad_edit_online",
          "asset_ad_account_apply"
        ]
      },
      {
        "path": "/policy",
        "name": "账户策略",
        "icon": "profile",
        "routes": [
          {
            "path": "/policy/ad",
            "name": "关闭广告",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Policy__ClosedAd' */'/src/pages/Policy/ClosedAd'), loading: LoadingComponent}),
            "authority": [
              "get_close_ad_info"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/policy/logs",
            "name": "日志查看",
            "icon": "calendar",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Policy__Log' */'/src/pages/Policy/Log'), loading: LoadingComponent}),
            "authority": [
              "get_close_ad_log"
            ],
            "access": "permissionFilter",
            "exact": true
          }
        ],
        "authority": [
          "get_close_ad_info",
          "get_close_ad_log"
        ]
      },
      {
        "path": "/report",
        "name": "运营报表",
        "icon": "container",
        "routes": [
          {
            "path": "/report/group",
            "name": "团队ROI报表",
            "icon": "account-book",
            "authority": [
              "get_group_roi",
              "get_profit"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__GroupRoi' */'/src/pages/Report/GroupRoi'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/report/operate-situation-analysis",
            "name": "运营态势分析",
            "icon": "account-book",
            "authority": [
              "people_situation"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__OperationSituationAnalysis' */'/src/pages/Report/OperationSituationAnalysis'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/report/operate-health-analysis",
            "name": "运营健康度分析",
            "icon": "account-book",
            "authority": [
              "get_operators_health"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__OperationHealthAnalysis' */'/src/pages/Report/OperationHealthAnalysis'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/report/skusales-old",
            "name": "SKU销量",
            "icon": "shop",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__SkuSalesOld' */'/src/pages/Report/SkuSalesOld'), loading: LoadingComponent}),
            "authority": [
              "get_old_sku_sales"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/skusales",
            "name": "SKU销量",
            "icon": "shop",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__SkuSales' */'/src/pages/Report/SkuSales'), loading: LoadingComponent}),
            "authority": [
              "get_domain_sku_sales",
              "get_total_sku_sales"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/grossprofit",
            "name": "毛利核算",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__GrossProfit' */'/src/pages/Report/GrossProfit'), loading: LoadingComponent}),
            "authority": [
              "get_profit"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/get-profit",
            "name": "毛利核算(旧版)",
            "icon": "calculator",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__GetProfit' */'/src/pages/Report/GetProfit'), loading: LoadingComponent}),
            "authority": [
              "gross_module_debug"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/skucalc",
            "name": "SKU核算",
            "icon": "calculator",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__SkuCalc' */'/src/pages/Report/SkuCalc'), loading: LoadingComponent}),
            "authority": [
              "order_sku_calc",
              "get_country_sku_calc",
              "sku_calc",
              "sku_freight_config",
              "sku_attribute_config",
              "edit_sku_attribute_config"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/rank",
            "name": "业绩排行榜",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Rank' */'/src/pages/Report/Rank'), loading: LoadingComponent}),
            "authority": [
              "get_performance_rank"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/countrySale",
            "name": "国家销量",
            "icon": "calculator",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__CountrySales' */'/src/pages/Report/CountrySales'), loading: LoadingComponent}),
            "authority": [
              "get_domain_country_sales_insensitive",
              "get_domain_country_sales"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/freightcount",
            "name": "运费统计对比",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__FreightCount' */'/src/pages/Report/FreightCount'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "authority": [
              "freight_statistics_comparison"
            ],
            "exact": true
          },
          {
            "path": "/report/new-product-test-board",
            "name": "新品测品看板",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__NewProductTestBoard' */'/src/pages/Report/NewProductTestBoard'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "authority": [
              "new_product_board_page"
            ],
            "exact": true
          },
          {
            "path": "/report/sku-shipping-status",
            "name": "SKU发货情况",
            "icon": "bar-chart",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__SkuShippingStatus' */'/src/pages/Report/SkuShippingStatus'), loading: LoadingComponent}),
            "authority": [
              "sku_shipping"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/ordership",
            "name": "订单发货率分析",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__OrderShip' */'/src/pages/Report/OrderShip'), loading: LoadingComponent}),
            "authority": [
              "view_express_rates"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/categoryship",
            "name": "品类发货率分析",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Categoryship' */'/src/pages/Report/Categoryship'), loading: LoadingComponent}),
            "authority": [
              "category_express_rate_info",
              "real_category_express_rate_info"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/shippingRate",
            "name": "发货时效投诉率",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__ShippingRate' */'/src/pages/Report/ShippingRate'), loading: LoadingComponent}),
            "authority": [
              "insiders_view_express_complaints"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/warehouse-shippingRate-within-one-day",
            "name": "仓库24小时发货率统计",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__WarehouseShippingRate' */'/src/pages/Report/WarehouseShippingRate'), loading: LoadingComponent}),
            "authority": [
              "warehouse_24_hour_delivery_rate"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/report/issuing-warehouse-delivery",
            "name": "代发仓发货统计",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__IssuingWarehouseDelivery' */'/src/pages/Report/IssuingWarehouseDelivery'), loading: LoadingComponent}),
            "authority": [
              "delivery_warehouse_statistics"
            ],
            "access": "permissionFilter",
            "exact": true
          }
        ],
        "authority": [
          "get_group_roi",
          "get_profit",
          "people_situation",
          "get_operators_health",
          "get_old_sku_sales",
          "get_domain_sku_sales",
          "get_total_sku_sales",
          "get_profit",
          "gross_module_debug",
          "order_sku_calc",
          "get_country_sku_calc",
          "sku_calc",
          "sku_freight_config",
          "sku_attribute_config",
          "edit_sku_attribute_config",
          "get_performance_rank",
          "get_domain_country_sales_insensitive",
          "get_domain_country_sales",
          "freight_statistics_comparison",
          "new_product_board_page",
          "sku_shipping",
          "view_express_rates",
          "category_express_rate_info",
          "real_category_express_rate_info",
          "insiders_view_express_complaints",
          "warehouse_24_hour_delivery_rate",
          "delivery_warehouse_statistics"
        ]
      },
      {
        "path": "/statistic",
        "name": "统计报表",
        "icon": "profile",
        "routes": [
          {
            "path": "/statistic/ad-disapprove-situation-analysis",
            "name": "拒登广告账号情况分析",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__AdDisapproveSituationAnalysis' */'/src/pages/Statistic/AdDisapproveSituationAnalysis'), loading: LoadingComponent}),
            "authority": [
              "disapproved_ads_info"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/ad-report",
            "name": "广告投放报表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__AdReport' */'/src/pages/Statistic/AdReport'), loading: LoadingComponent}),
            "authority": [
              "ad_page_statistics"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/larr-statistics",
            "name": "LARR数据统计",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__LARRStatistics' */'/src/pages/Statistic/LARRStatistics'), loading: LoadingComponent}),
            "authority": [
              "read_larr_statistics"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/advertisingAccountStatistics",
            "name": "广告账号异常情况统计",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__AdvertisingAccountStatistics' */'/src/pages/Statistic/AdvertisingAccountStatistics'), loading: LoadingComponent}),
            "authority": [
              "ad_account_statistics_related_info_pate",
              "ad_account_statistics_exception_handle_page",
              "ad_account_statistics_proxy_page",
              "ad_account_statistics_abnormal_page",
              "ad_account_statistics_page"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/simple-order-analysis",
            "name": "单品订单统计分析",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__SimpleOrderAnalysis' */'/src/pages/Statistic/SimpleOrderAnalysis'), loading: LoadingComponent}),
            "authority": [
              "single_order_sales_analyze"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/launchAuditStatistics",
            "name": "投放审核数据统计",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__LaunchAuditStatistics' */'/src/pages/Statistic/LaunchAuditStatistics'), loading: LoadingComponent}),
            "authority": [
              "index_audit_results_post",
              "index_audit_results_page",
              "index_audit_results_domain",
              "index_audit_results_landing",
              "index_part_audit_results",
              "index_provider_audit_results",
              "get_reviewer_analyze"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/team-operation-status",
            "name": "团队运营情况",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__TeamOperationStatus' */'/src/pages/Statistic/TeamOperationStatus'), loading: LoadingComponent}),
            "authority": [
              "team_operate_statistics"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/operatorNumber",
            "name": "团队运营人数",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__OperatorNumber' */'/src/pages/Statistic/OperatorNumber'), loading: LoadingComponent}),
            "authority": [
              "get_user_incumbency_statistics"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/peoplequantity",
            "name": "运营质量分析",
            "icon": "account-book",
            "authority": [
              "operational_quality_analysis"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__PeopleQuantity' */'/src/pages/Statistic/PeopleQuantity'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/statistic/approve",
            "name": "审批报表",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__Approve' */'/src/pages/Statistic/Approve'), loading: LoadingComponent}),
            "authority": [
              "get_asset_approve_report"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/appeal",
            "name": "申诉报表",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__Appeal' */'/src/pages/Statistic/Appeal'), loading: LoadingComponent}),
            "authority": [
              "get_account_appeal_report"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/bm",
            "name": "BM统计",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__Bm' */'/src/pages/Statistic/Bm'), loading: LoadingComponent}),
            "authority": [
              "get_bm_report"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/orderreport",
            "name": "工单报表",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__OrderReport' */'/src/pages/Statistic/OrderReport'), loading: LoadingComponent}),
            "authority": [
              "get_error_msg_report"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/asset_consumption",
            "name": "资产消耗",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__AssetConsumption' */'/src/pages/Statistic/AssetConsumption'), loading: LoadingComponent}),
            "authority": [
              "get_asset_consumption_report"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/advertising-account",
            "name": "广告账户统计",
            "icon": "unordered-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__AdvertisingAccount' */'/src/pages/Statistic/AdvertisingAccount'), loading: LoadingComponent}),
            "authority": [
              "get_ad_account_statistics"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/cost-statistics",
            "name": "物流费用统计",
            "icon": "bars",
            "authority": [
              "view_logistics_freight_statistics"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__CostStatistics' */'/src/pages/Track/CostStatistics'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/statistic/logistics_timeliness_statistics",
            "name": "物流时效统计",
            "icon": "bars",
            "authority": [
              "fulfillment_timeliness_statistics"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Track__LogisticsTimelinessStatistics' */'/src/pages/Track/LogisticsTimelinessStatistics'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/statistic/subject-expense",
            "name": "主体花费",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistic__SubjectExpense' */'/src/pages/Statistic/SubjectExpense'), loading: LoadingComponent}),
            "authority": [
              "view_entity_spend"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/statistic/operating-monitor",
            "name": "运营监测",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cortana__OperatingMonitor' */'/src/pages/Cortana/OperatingMonitor'), loading: LoadingComponent}),
            "authority": [
              "cortana_operating_trend"
            ],
            "exact": true
          }
        ],
        "authority": [
          "disapproved_ads_info",
          "ad_page_statistics",
          "read_larr_statistics",
          "ad_account_statistics_related_info_pate",
          "ad_account_statistics_exception_handle_page",
          "ad_account_statistics_proxy_page",
          "ad_account_statistics_abnormal_page",
          "ad_account_statistics_page",
          "single_order_sales_analyze",
          "index_audit_results_post",
          "index_audit_results_page",
          "index_audit_results_domain",
          "index_audit_results_landing",
          "index_part_audit_results",
          "index_provider_audit_results",
          "get_reviewer_analyze",
          "team_operate_statistics",
          "get_user_incumbency_statistics",
          "operational_quality_analysis",
          "get_asset_approve_report",
          "get_account_appeal_report",
          "get_bm_report",
          "get_error_msg_report",
          "get_asset_consumption_report",
          "get_ad_account_statistics",
          "view_logistics_freight_statistics",
          "fulfillment_timeliness_statistics",
          "view_entity_spend",
          "cortana_operating_trend"
        ]
      },
      {
        "path": "/msg",
        "name": "工单提交",
        "icon": "edit",
        "authority": [
          "get_error_msgs"
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ErrorMsg' */'/src/pages/ErrorMsg'), loading: LoadingComponent}),
        "access": "permissionFilter",
        "exact": true
      },
      {
        "path": "/feedback",
        "name": "建议反馈",
        "icon": "edit",
        "authority": [
          "get_suggest_list"
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Feedback' */'/src/pages/Feedback'), loading: LoadingComponent}),
        "access": "permissionFilter",
        "exact": true
      },
      {
        "path": "/review",
        "name": "审核中心",
        "icon": "exception",
        "routes": [
          {
            "path": "/review/riskControl",
            "name": "风控审核",
            "icon": "video-camera",
            "authority": [
              "view_risk_control"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__RiskControlAudit' */'/src/pages/Review/RiskControlAudit'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/specialStoreScan",
            "name": "特殊店铺违规落地页扫描",
            "icon": "video-camera",
            "authority": [
              "special_store_scan"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__SpecialStoreScan' */'/src/pages/Review/SpecialStoreScan'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/launchSearch",
            "name": "投放审核查询",
            "icon": "video-camera",
            "authority": [
              "delivery_review"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__Launch' */'/src/pages/Review/Launch'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/adRequest",
            "name": "广告帖ID查询",
            "icon": "video-camera",
            "authority": [
              "ad_object_story"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AdRequest' */'/src/pages/Review/AdRequest'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/account",
            "name": "账号资源组",
            "icon": "book",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AccountAssets' */'/src/pages/Review/AccountAssets'), loading: LoadingComponent}),
            "authority": [
              "get_asset_apply",
              "get_asset_apply_assign",
              "get_asset_apply_recycle",
              "get_asset_page_apply",
              "get_asset_page_recycle",
              "get_asset_pixel_apply",
              "get_asset_pixel_recycle",
              "get_asset_apply"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/ad/spotcheck",
            "name": "资产抽检审核",
            "authority": [
              "view_sampling_material"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AdSpotCheck' */'/src/pages/Review/AdSpotCheck'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/ad/material",
            "name": "素材审核",
            "icon": "video-camera",
            "authority": [
              "ad_reviews",
              "mg_ad_reviews",
              "yn_ad_reviews",
              "lh_ad_reviews",
              "ppy_ad_reviews",
              "lb_ad_reviews"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AdMaterial' */'/src/pages/Review/AdMaterial'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/ad/compliant_material_example",
            "name": "合规素材库",
            "authority": [
              "get_normal_materials"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__CompliantAdMaterialExample' */'/src/pages/Review/CompliantAdMaterialExample'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/ad/infringement_material_library",
            "name": "侵权展示库",
            "authority": [
              "get_infringe_detail"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__InfringementAdMaterialLibrary' */'/src/pages/Review/InfringementAdMaterialLibrary'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/ad/infringement_material_example",
            "name": "案例管理",
            "authority": [
              "get_infringe_case"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__InfringementAdMaterialExample' */'/src/pages/Review/InfringementAdMaterialExample'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/ad/infringement_material_example/add-case",
            "name": "新增案例",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__InfringementAdMaterialExample__AddCase__index' */'/src/pages/Review/InfringementAdMaterialExample/AddCase/index'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/review/ad/infringement_material_example/det-case",
            "name": "案例详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__InfringementAdMaterialExample__DetCase__index' */'/src/pages/Review/InfringementAdMaterialExample/DetCase/index'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/review/ad/category_manage",
            "name": "品类管理",
            "authority": [
              "infringe_category"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__CategoryManage' */'/src/pages/Review/CategoryManage'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/ad/fail_material",
            "name": "违规案例",
            "icon": "video-camera",
            "authority": [
              "get_part_fail_ad_reviews"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__FailMaterial' */'/src/pages/Review/FailMaterial'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/ad/charge",
            "name": "广告户充值",
            "icon": "form",
            "authority": [
              "get_charge_application_list"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AdCharge' */'/src/pages/Review/AdCharge'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/ad/clear",
            "name": "广告户清零",
            "icon": "form",
            "authority": [
              "get_clear_application_list"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AdClear' */'/src/pages/Review/AdClear'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/ad/apply",
            "name": "广告户申请",
            "icon": "form",
            "authority": [
              "get_account_application_list"
            ],
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AdApply' */'/src/pages/Review/AdApply'), loading: LoadingComponent}),
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/appeal",
            "name": "账号申诉",
            "icon": "notification",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AdAppeal' */'/src/pages/Review/AdAppeal'), loading: LoadingComponent}),
            "authority": [
              "get_account_appeals"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/email-apply",
            "name": "邮箱申请",
            "icon": "notification",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__EmailApply' */'/src/pages/Review/EmailApply'), loading: LoadingComponent}),
            "authority": [
              "get_email_apply"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/old-bm-change",
            "name": "旧系统个人号维护",
            "icon": "notification",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__OldBMChangeApply' */'/src/pages/Review/OldBMChangeApply'), loading: LoadingComponent}),
            "authority": [
              "get_old_bm_change_email"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/bm-asset",
            "name": "更换BM申请",
            "icon": "book",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__BmAsset' */'/src/pages/Review/BmAsset'), loading: LoadingComponent}),
            "authority": [
              "get_bm_change_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/old-bm-apply",
            "name": "旧系统移户申请",
            "icon": "book",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__OldBmApply' */'/src/pages/Review/OldBmApply'), loading: LoadingComponent}),
            "authority": [
              "deal_old_bm_change"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/double-verify",
            "name": "双重验证",
            "icon": "book",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__DoubleVerify' */'/src/pages/Review/DoubleVerify'), loading: LoadingComponent}),
            "authority": [
              "deal_email_double_verify"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/bm-api-change",
            "name": "更换BM API账号",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__BmApiChange' */'/src/pages/Review/BmApiChange'), loading: LoadingComponent}),
            "authority": [
              "bm_api_change"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/personal-number",
            "name": "个人号管理",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__PersonalNumber' */'/src/pages/Review/PersonalNumber'), loading: LoadingComponent}),
            "authority": [
              "change_email_pwd",
              "get_email_secret_info",
              "pixel_belong_inquire"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/pixel-performance",
            "name": "像素成效",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__PixelPerformance' */'/src/pages/Review/PixelPerformance'), loading: LoadingComponent}),
            "authority": [
              "get_pixel_effective"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/landing-page",
            "name": "落地页审核",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__LandingPage' */'/src/pages/Review/LandingPage'), loading: LoadingComponent}),
            "authority": [
              "get_landing_page_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/asset-review",
            "name": "资产审核",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AssetReview' */'/src/pages/Review/AssetReview'), loading: LoadingComponent}),
            "authority": [
              "view_asset_review"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/shop-ticket",
            "name": "店铺工单",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__ShopTicket' */'/src/pages/Review/ShopTicket'), loading: LoadingComponent}),
            "authority": [
              "get_store_ticket_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/ad-off-shelf",
            "name": "广告下架",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AdOffShelf' */'/src/pages/Review/AdOffShelf'), loading: LoadingComponent}),
            "authority": [
              "remove_ads_by_sku"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/abnormal-order-deal",
            "name": "异常订单处理",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AbnormalOrderDealNew' */'/src/pages/Review/AbnormalOrderDealNew'), loading: LoadingComponent}),
            "authority": [
              "abnormal_order_not_review_tab",
              "abnormal_order_abnormal_review_tab",
              "abnormal_order_failed_review_tab",
              "abnormal_order_abnormal_sku_tab"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/infringing-keywords",
            "name": "侵权关键词管理",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__InfringingKeywords' */'/src/pages/Review/InfringingKeywords'), loading: LoadingComponent}),
            "authority": [
              "show_infringing_keywords"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/sku-nice-category-setting",
            "name": "SKU尼斯分类设置",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__SkuNiceCategorySetting' */'/src/pages/Review/SkuNiceCategorySetting'), loading: LoadingComponent}),
            "authority": [
              "v_sku_nice_type_list",
              "t_sku_nice_type_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/landingpageadmin",
            "name": "落地页风险管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__landingPageAdmin__TabConfig' */'/src/pages/Review/landingPageAdmin/TabConfig'), loading: LoadingComponent}),
            "authority": [
              "read_key_word",
              "read_key_word_page",
              "read_pages_illegal_picture"
            ],
            "exact": true
          },
          {
            "path": "/review/product-after-sales-review",
            "name": "产品售后审核",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__ProductAfterSalesReview__index' */'/src/pages/Review/ProductAfterSalesReview/index'), loading: LoadingComponent}),
            "authority": [
              "sale_sku_product_check",
              "sale_sku_landing_check",
              "sale_sku_ship_check",
              "sale_sku_rehear_check",
              "sale_sku_only_view"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/after-sales-review",
            "name": "售后审核",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__AfterSalesReview' */'/src/pages/Review/AfterSalesReview'), loading: LoadingComponent}),
            "authority": [
              "sale_after_review_url",
              "sale_after_review_dispute"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/review-performance-dashboard",
            "name": "审核绩效看板",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__ReviewPerformanceDashboard' */'/src/pages/Review/ReviewPerformanceDashboard'), loading: LoadingComponent}),
            "authority": [
              "after_sale_review_statistics",
              "after_sale_review_dispute_statistics",
              "risk_performance_statistics"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/force-removal-review",
            "name": "强制下架审核",
            "authority": [
              "take_down_review_list",
              "vs_sku_infringement_review_list",
              "view_take_down_task",
              "view_high_risk_resource"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__ForceRemovalReview' */'/src/pages/Review/ForceRemovalReview'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/infringing-manager",
            "name": "侵权管理",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__InfringingManager' */'/src/pages/Review/InfringingManager'), loading: LoadingComponent}),
            "authority": [
              "read_post_infringements",
              "read_sku_infringements",
              "read_check_asset_infringements",
              "read_asset_infringements"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/tiktok",
            "name": "TT素材审核",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__Tiktok' */'/src/pages/Review/Tiktok'), loading: LoadingComponent}),
            "authority": [
              "view_tiktok_review"
            ],
            "exact": true
          },
          {
            "path": "/review/tiktok-release-record",
            "name": "TT素材试投放记录",
            "icon": "form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__TikTokReleaseRecord' */'/src/pages/Review/TikTokReleaseRecord'), loading: LoadingComponent}),
            "authority": [
              "read_tik_tok_material_put_record",
              "import_tik_tok_material_put_record",
              "write_tik_tok_material_put_record"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/review/ad/compliant-product",
            "name": "合规库产品",
            "authority": [
              "get_compliant_product"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__CompliantProduct' */'/src/pages/Review/CompliantProduct'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/result-appeal",
            "name": "审核申诉",
            "authority": [
              "check_product",
              "read_review_appeals"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__ResultAppeal' */'/src/pages/Review/ResultAppeal'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/review/experimental-product",
            "name": "产品研发库",
            "authority": [
              "read_research_product"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Review__ExperimentalProduct' */'/src/pages/Review/ExperimentalProduct'), loading: LoadingComponent}),
            "exact": true
          }
        ],
        "authority": [
          "view_risk_control",
          "special_store_scan",
          "delivery_review",
          "ad_object_story",
          "get_asset_apply",
          "get_asset_apply_assign",
          "get_asset_apply_recycle",
          "get_asset_page_apply",
          "get_asset_page_recycle",
          "get_asset_pixel_apply",
          "get_asset_pixel_recycle",
          "get_asset_apply",
          "view_sampling_material",
          "ad_reviews",
          "mg_ad_reviews",
          "yn_ad_reviews",
          "lh_ad_reviews",
          "ppy_ad_reviews",
          "lb_ad_reviews",
          "get_normal_materials",
          "get_infringe_detail",
          "get_infringe_case",
          "infringe_category",
          "get_part_fail_ad_reviews",
          "get_charge_application_list",
          "get_clear_application_list",
          "get_account_application_list",
          "get_account_appeals",
          "get_email_apply",
          "get_old_bm_change_email",
          "get_bm_change_list",
          "deal_old_bm_change",
          "deal_email_double_verify",
          "bm_api_change",
          "change_email_pwd",
          "get_email_secret_info",
          "pixel_belong_inquire",
          "get_pixel_effective",
          "get_landing_page_list",
          "view_asset_review",
          "get_store_ticket_list",
          "remove_ads_by_sku",
          "abnormal_order_not_review_tab",
          "abnormal_order_abnormal_review_tab",
          "abnormal_order_failed_review_tab",
          "abnormal_order_abnormal_sku_tab",
          "show_infringing_keywords",
          "v_sku_nice_type_list",
          "t_sku_nice_type_list",
          "read_key_word",
          "read_key_word_page",
          "read_pages_illegal_picture",
          "sale_sku_product_check",
          "sale_sku_landing_check",
          "sale_sku_ship_check",
          "sale_sku_rehear_check",
          "sale_sku_only_view",
          "sale_after_review_url",
          "sale_after_review_dispute",
          "after_sale_review_statistics",
          "after_sale_review_dispute_statistics",
          "risk_performance_statistics",
          "take_down_review_list",
          "vs_sku_infringement_review_list",
          "view_take_down_task",
          "view_high_risk_resource",
          "read_post_infringements",
          "read_sku_infringements",
          "read_check_asset_infringements",
          "read_asset_infringements",
          "view_tiktok_review",
          "read_tik_tok_material_put_record",
          "import_tik_tok_material_put_record",
          "write_tik_tok_material_put_record",
          "get_compliant_product",
          "check_product",
          "read_review_appeals",
          "read_research_product"
        ]
      },
      {
        "path": "/personal",
        "name": "个人中心",
        "icon": "user",
        "routes": [
          {
            "path": "/personal/info",
            "name": "基本信息",
            "icon": "profile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Info' */'/src/pages/User/Info'), loading: LoadingComponent}),
            "authority": [
              "view_user_info"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/personal/rate",
            "name": "汇率查询",
            "icon": "bank",
            "authority": [
              "get_rate"
            ],
            "access": "permissionFilter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Rate' */'/src/pages/User/Rate'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/personal/daily",
            "name": "工作日报",
            "icon": "profile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Daily' */'/src/pages/User/Daily'), loading: LoadingComponent}),
            "authority": [
              "sku_daily_list"
            ],
            "access": "permissionFilter",
            "exact": true
          },
          {
            "path": "/personal/browser_tool",
            "name": "下载中心",
            "icon": "profile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__BrowserTool' */'/src/pages/User/BrowserTool'), loading: LoadingComponent}),
            "authority": [
              "browser_tools_download"
            ],
            "access": "permissionFilter",
            "exact": true
          }
        ],
        "authority": [
          "view_user_info",
          "get_rate",
          "sku_daily_list",
          "browser_tools_download"
        ]
      },
      {
        "path": "/DataDownload",
        "name": "数据下载",
        "icon": "arrow-down",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DataDownload__SkuAudRecord' */'/src/pages/DataDownload/SkuAudRecord'), loading: LoadingComponent}),
        "authority": [
          "user_download_task_check_sku_record",
          "user_download_task_uncheck_part_order",
          "user_download_task_team_maban_order",
          "user_download_task_thursday_maban_order",
          "user_download_orders_of_over_check_day",
          "user_download_task_month4_maban_order",
          "user_download_task_month4_ma_bang_express",
          "user_download_task_month4_ma_bang_order_detail",
          "user_download_task_export_daily_split_order",
          "month_paid_order",
          "month_hot_sale",
          "user_download_task_salesmonitory",
          "month_freight",
          "user_download_task_daily_address",
          "download_landing_page_analysis"
        ],
        "exact": true
      },
      {
        "path": "/material/dispatch",
        "name": "素材派单",
        "icon": "video-camera",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MaterialDispatch' */'/src/pages/MaterialDispatch'), loading: LoadingComponent}),
        "authority": [
          "visit_clip_task"
        ],
        "access": "permissionFilter",
        "exact": true
      },
      {
        "path": "/message",
        "name": "消息中心",
        "icon": "message",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Message' */'/src/pages/Message'), loading: LoadingComponent}),
        "authority": [
          "message_center"
        ],
        "access": "permissionFilter",
        "exact": true
      },
      {
        "path": "/faq",
        "name": "帮助手册",
        "icon": "container",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Faq' */'/src/pages/Faq'), loading: LoadingComponent}),
        "authority": [
          "help_manual"
        ],
        "access": "permissionFilter",
        "exact": true
      },
      {
        "path": "/utils",
        "name": "实用工具",
        "icon": "tool",
        "routes": [
          {
            "path": "/utils/text2image",
            "name": "文字转图片",
            "icon": "fileImage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Utils__Text2Image' */'/src/pages/Utils/Text2Image'), loading: LoadingComponent}),
            "authority": [
              "tool_text2image"
            ],
            "access": "permissionFilter",
            "exact": true
          }
        ],
        "authority": [
          "tool_text2image"
        ]
      },
      {
        "path": "/update-log",
        "name": "更新日志",
        "icon": "container",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChangeLog' */'/src/pages/ChangeLog'), loading: LoadingComponent}),
        "authority": [
          "get_change_log_operation",
          "get_change_log_article"
        ],
        "access": "permissionFilter",
        "exact": true
      },
      {
        "path": "/google-authorization",
        "icon": "container",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__GoogleAuthorization' */'/src/pages/GoogleAuthorization'), loading: LoadingComponent}),
        "access": "permissionFilter",
        "exact": true
      },
      {
        "path": "/500",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__500' */'/src/pages/500'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ],
    "authority": [
      "dashboard",
      "get_stores_orders",
      "order_details",
      "platform_analysis",
      "order_compare_analysis",
      "order_group_payment_analysis",
      "ws_abandoned_list",
      "order_refund_detail",
      "only_domain_dispute_list",
      "sku_dispute_list",
      "pp_dispute_list",
      "store_dispute_list",
      "logistics_query",
      "logistics_ticket",
      "virtual_real_track_search",
      "order_logistics_processing",
      "order_address_verification",
      "tracking_hub_search",
      "update_fulfillments",
      "write_fulfillment_channels_configs",
      "write_fulfillment_channels_rates",
      "read_fulfillment_channels_prices",
      "read_fulfillment_channels_statistics",
      "write_fulfillment_channels_sku_books",
      "optimal_channel_online_calculation",
      "read_fulfillment_cost_details",
      "usps_index",
      "account_spend",
      "ad_tt_ad_spend",
      "google_account_spend",
      "cpm_analysis",
      "real_time_ad_spend",
      "ad_entity_spend",
      "get_sku_review",
      "show_violating_product",
      "deal_erp_completion",
      "deal_product_review",
      "deal_processing_method",
      "deal_infraction_type",
      "deal_violating_infraction",
      "sku_freight_change",
      "product_freight_trial",
      "weight_freight_trial",
      "product_roi_trial",
      "commodity_freight_trial",
      "selling_price_calc",
      "sku_replace_supply_index",
      "sku_hot_sale_pass_page",
      "sku_hot_sale_all_page",
      "user_show_seven_days_not_placed_purchase_sku",
      "admin_show_seven_days_not_placed_purchase_sku",
      "porduct_test_list",
      "customized_product_management",
      "get_history_sku",
      "get_sku_review",
      "purchase_supply_chain_update",
      "purchase_supply_chain_ts_info",
      "purchase_supply_chain_vs_info",
      "purchase_supply_chain_storage_info",
      "sku_category_product_statistics",
      "sku_product_statistics",
      "delete_sku_detail",
      "view_sku_void_order_summary",
      "show_sku_daily_review_statistics",
      "on_offer_sku_manage",
      "un_shelve_sku",
      "stock_sku_search",
      "stock_sku_manage",
      "get_sku_infringement_list",
      "get_recommend_product_list",
      "get_stock_sku",
      "sku_stock_situation",
      "customize_cate_not_create_del",
      "stock_up_application_index",
      "sku_supplier_page",
      "read_sku_postal_country_configs",
      "write_sku_postal_country_configs",
      "abnormal_cost_sku",
      "view_stop_sku_record",
      "edit_stop_sku_record",
      "view_bom_supply_chain_list",
      "product_import",
      "product_import",
      "sku_review_batch_import",
      "import_export_statistics",
      "pay_large_changes_search",
      "pp_config_record_index",
      "pp_config_record_update",
      "pp_amount",
      "pp_actual_amount",
      "pp_amount",
      "show_pp_list",
      "show_xb_list",
      "show_wp_list",
      "third_credit_card_manage",
      "read_fourth_credit_card",
      "klarna_pay_config_view",
      "read_wallets",
      "write_wallets",
      "pp_health_examination",
      "domain_order_transaction_list",
      "domain_pp_change_list",
      "pp_alarm_list",
      "read_credit_card_application_list",
      "view_ad_account_register_info",
      "get_ad_account",
      "read_tik_tok_ads",
      "get_inventory_works",
      "get_users_list",
      "get_hermes_users_list",
      "get_parts",
      "google_ad_accounts_list",
      "get_stores",
      "excel_update_store_pp",
      "store_change_history",
      "get_part_configs",
      "get_asset",
      "view_fb_assign_authorized",
      "read_entity_unassigned_accounts",
      "view_account_shared_bm",
      "get_domain_list",
      "page_manage",
      "view_facebook_page",
      "get_third_domain_log",
      "get_credit_card_list",
      "get_pixel_list",
      "bm_token_manager_user_token",
      "bm_token_manager_other_token",
      "bm_token_manager_user_app_token",
      "read_tik_tok_personal_accounts",
      "view_all_tt_data",
      "ad_edit_online",
      "asset_ad_account_apply",
      "get_close_ad_info",
      "get_close_ad_log",
      "get_group_roi",
      "get_profit",
      "people_situation",
      "get_operators_health",
      "get_old_sku_sales",
      "get_domain_sku_sales",
      "get_total_sku_sales",
      "get_profit",
      "gross_module_debug",
      "order_sku_calc",
      "get_country_sku_calc",
      "sku_calc",
      "sku_freight_config",
      "sku_attribute_config",
      "edit_sku_attribute_config",
      "get_performance_rank",
      "get_domain_country_sales_insensitive",
      "get_domain_country_sales",
      "freight_statistics_comparison",
      "new_product_board_page",
      "sku_shipping",
      "view_express_rates",
      "category_express_rate_info",
      "real_category_express_rate_info",
      "insiders_view_express_complaints",
      "warehouse_24_hour_delivery_rate",
      "delivery_warehouse_statistics",
      "disapproved_ads_info",
      "ad_page_statistics",
      "read_larr_statistics",
      "ad_account_statistics_related_info_pate",
      "ad_account_statistics_exception_handle_page",
      "ad_account_statistics_proxy_page",
      "ad_account_statistics_abnormal_page",
      "ad_account_statistics_page",
      "single_order_sales_analyze",
      "index_audit_results_post",
      "index_audit_results_page",
      "index_audit_results_domain",
      "index_audit_results_landing",
      "index_part_audit_results",
      "index_provider_audit_results",
      "get_reviewer_analyze",
      "team_operate_statistics",
      "get_user_incumbency_statistics",
      "operational_quality_analysis",
      "get_asset_approve_report",
      "get_account_appeal_report",
      "get_bm_report",
      "get_error_msg_report",
      "get_asset_consumption_report",
      "get_ad_account_statistics",
      "view_logistics_freight_statistics",
      "fulfillment_timeliness_statistics",
      "view_entity_spend",
      "cortana_operating_trend",
      "get_error_msgs",
      "get_suggest_list",
      "view_risk_control",
      "special_store_scan",
      "delivery_review",
      "ad_object_story",
      "get_asset_apply",
      "get_asset_apply_assign",
      "get_asset_apply_recycle",
      "get_asset_page_apply",
      "get_asset_page_recycle",
      "get_asset_pixel_apply",
      "get_asset_pixel_recycle",
      "get_asset_apply",
      "view_sampling_material",
      "ad_reviews",
      "mg_ad_reviews",
      "yn_ad_reviews",
      "lh_ad_reviews",
      "ppy_ad_reviews",
      "lb_ad_reviews",
      "get_normal_materials",
      "get_infringe_detail",
      "get_infringe_case",
      "infringe_category",
      "get_part_fail_ad_reviews",
      "get_charge_application_list",
      "get_clear_application_list",
      "get_account_application_list",
      "get_account_appeals",
      "get_email_apply",
      "get_old_bm_change_email",
      "get_bm_change_list",
      "deal_old_bm_change",
      "deal_email_double_verify",
      "bm_api_change",
      "change_email_pwd",
      "get_email_secret_info",
      "pixel_belong_inquire",
      "get_pixel_effective",
      "get_landing_page_list",
      "view_asset_review",
      "get_store_ticket_list",
      "remove_ads_by_sku",
      "abnormal_order_not_review_tab",
      "abnormal_order_abnormal_review_tab",
      "abnormal_order_failed_review_tab",
      "abnormal_order_abnormal_sku_tab",
      "show_infringing_keywords",
      "v_sku_nice_type_list",
      "t_sku_nice_type_list",
      "read_key_word",
      "read_key_word_page",
      "read_pages_illegal_picture",
      "sale_sku_product_check",
      "sale_sku_landing_check",
      "sale_sku_ship_check",
      "sale_sku_rehear_check",
      "sale_sku_only_view",
      "sale_after_review_url",
      "sale_after_review_dispute",
      "after_sale_review_statistics",
      "after_sale_review_dispute_statistics",
      "risk_performance_statistics",
      "take_down_review_list",
      "vs_sku_infringement_review_list",
      "view_take_down_task",
      "view_high_risk_resource",
      "read_post_infringements",
      "read_sku_infringements",
      "read_check_asset_infringements",
      "read_asset_infringements",
      "view_tiktok_review",
      "read_tik_tok_material_put_record",
      "import_tik_tok_material_put_record",
      "write_tik_tok_material_put_record",
      "get_compliant_product",
      "check_product",
      "read_review_appeals",
      "read_research_product",
      "view_user_info",
      "get_rate",
      "sku_daily_list",
      "browser_tools_download",
      "user_download_task_check_sku_record",
      "user_download_task_uncheck_part_order",
      "user_download_task_team_maban_order",
      "user_download_task_thursday_maban_order",
      "user_download_orders_of_over_check_day",
      "user_download_task_month4_maban_order",
      "user_download_task_month4_ma_bang_express",
      "user_download_task_month4_ma_bang_order_detail",
      "user_download_task_export_daily_split_order",
      "month_paid_order",
      "month_hot_sale",
      "user_download_task_salesmonitory",
      "month_freight",
      "user_download_task_daily_address",
      "download_landing_page_analysis",
      "visit_clip_task",
      "message_center",
      "help_manual",
      "tool_text2image",
      "get_change_log_operation",
      "get_change_log_article"
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
